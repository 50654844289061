import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT } from "common/constants";
import { loadStateWithKey, saveStateWithKey } from "common/redux/redux-utils";
import { accountsApi as agencyAccountsApi } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { accountsApi as creditorAccountsApi } from "features/accountsTable/creditorPortal/accountsTableAPI";
import moment from "moment-timezone";

export const LOCAL_STORAGE_KEY = "state:accountsTable";

const saveState = saveStateWithKey(LOCAL_STORAGE_KEY, [
  "prevToken",
  "nextToken",
  "orderBy",
  "pageSize",
  "virtualPage",
  "selectedRows",
  "filteredAccounts",
  "resultCount",
  "resultNextToken",
  "resultPrevToken",
  "totalCount",
]);

export const INITIAL_PAGE_SIZE = 100;
export const INITIAL_ORDER_BY = null;
export const INITIAL_WORKLIST_ID = null;

// This state consists of variables that are needed for request and response
export const initialState = {
  uniqueDebtors: [],
  selectedRows: [],
  isShowDailyQueue: false,
  isShowPriorityQueue: false,
  // These are prevToken and nextToken that we use for request
  prevToken: null,
  nextToken: null,
  // These are prevToken and nextToken that we use for response
  resultPrevToken: null,
  resultNextToken: null,
  pageSize: null, // Set explicitly to null so we know it's the first time loading the page
  resultCount: 0,
  filteredAccounts: [],
  orderBy: INITIAL_ORDER_BY,
  totalCount: null,
  // Cursor pagination doesn't have a real page. We are showing "fake" or "virtual" page to the end user
  virtualPage: 1,
  worklistId: INITIAL_WORKLIST_ID,
  filters: {
    includeStatusExternalNames: [],
    excludeStatusExternalNames: [],
    minAccountBalance: null,
    maxAccountBalance: null,
    followUpStartDate: null,
    followUpEndDate: null,
    uploadDateStart: null,
    uploadDateEnd: null,
    includeTurnoverDateStart: null,
    includeTurnoverDateEnd: null,
    excludeTurnoverDateStart: null,
    excludeTurnoverDateEnd: null,
    includeLastCallDateStart: null,
    includeLastCallDateEnd: null,
    excludeLastCallDateStart: null,
    excludeLastCallDateEnd: null,
    excludeStatusCodes: [],
    includeStatusCodes: [],
    debtorStates: [],
    debtorTagIds: [],
    assigneeIds: [],
    creditorIds: [],
    creditorTagIds: [],
    hasLawsuit: null,
    collectionDebtTypes: null,
  },
};

// We want to reset some properties
export const loadAccountsTableSlice = () => ({
  ...initialState,
  ...loadStateWithKey(LOCAL_STORAGE_KEY),
});

export const accountsTableSlice = createSlice({
  name: "accountsTable",
  initialState,
  reducers: {
    setDailyQueueAccounts: (state, action) => {
      state.isShowDailyQueue = action.payload;
      state.filters.followUpStartDate = null;
      state.filters.followUpEndDate = null;
      if (state.isShowDailyQueue) {
        state.filters.followUpEndDate = moment().startOf("day").format(DATE_FORMAT);
      }
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setHasLawsuit: (state, action) => {
      state.filters.hasLawsuit = action.payload ? true : null;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setCollectionDebtTypes: (state, action) => {
      state.filters.collectionDebtTypes = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setPriorityQueueAccounts: (state, action) => {
      state.isShowPriorityQueue = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setDebtorStates: (state, action) => {
      state.filters.debtorStates = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setDebtorTagIds: (state, action) => {
      state.filters.debtorTagIds = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setAssigneeIds: (state, action) => {
      state.filters.assigneeIds = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setCreditorIds: (state, action) => {
      state.filters.creditorIds = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setCreditorTagIds: (state, action) => {
      state.filters.creditorTagIds = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
      saveState(state);
    },
    selectRows: (state, action) => {
      state.selectedRows = [...state.selectedRows, ...action.payload];
      saveState(state);
    },
    setFilterMinAccountBalance: (state, action) => {
      state.filters.minAccountBalance = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterMaxAccountBalance: (state, action) => {
      state.filters.maxAccountBalance = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterFollowUpDates: (state, action) => {
      if (!action.payload) {
        state.filters.followUpStartDate = null;
        state.filters.followUpEndDate = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.followUpStartDate, state.filters.followUpEndDate] = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterUploadDates: (state, action) => {
      if (!action.payload) {
        state.filters.uploadDateStart = null;
        state.filters.uploadDateEnd = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.uploadDateStart, state.filters.uploadDateEnd] = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterIncludeTurnoverDates: (state, action) => {
      if (!action.payload) {
        state.filters.includeTurnoverDateStart = null;
        state.filters.includeTurnoverDateEnd = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.includeTurnoverDateStart, state.filters.includeTurnoverDateEnd] =
        action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterExcludeTurnoverDates: (state, action) => {
      if (!action.payload) {
        state.filters.excludeTurnoverDateStart = null;
        state.filters.excludeTurnoverDateEnd = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.excludeTurnoverDateStart, state.filters.excludeTurnoverDateEnd] =
        action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterIncludeLastCallDates: (state, action) => {
      if (!action.payload) {
        state.filters.includeLastCallDateStart = null;
        state.filters.includeLastCallDateEnd = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.includeLastCallDateStart, state.filters.includeLastCallDateEnd] =
        action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterExcludeLastCallDates: (state, action) => {
      if (!action.payload) {
        state.filters.excludeLastCallDateStart = null;
        state.filters.excludeLastCallDateEnd = null;
        state.prevToken = null;
        state.nextToken = null;
        state.virtualPage = 1;
        saveState(state);
        return;
      }
      [state.filters.excludeLastCallDateStart, state.filters.excludeLastCallDateEnd] =
        action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterIncludeStatusCodes: (state, action) => {
      state.filters.includeStatusCodes = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterExcludeStatusCodes: (state, action) => {
      state.filters.excludeStatusCodes = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterIncludeStatusExternalNames: (state, action) => {
      state.filters.includeStatusExternalNames = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setFilterExcludeStatusExternalNames: (state, action) => {
      state.filters.excludeStatusExternalNames = action.payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setPagination: (state, { payload }) => {
      state.pageSize = payload.pageSize;
      state.prevToken = payload.prevToken;
      state.nextToken = payload.nextToken;
      state.virtualPage = payload.virtualPage;
      saveState(state);
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    setWorklistId: (state, { payload }) => {
      state.worklistId = payload;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      saveState(state);
    },
    resetFilters: (state, action) => {
      state.filters = initialState.filters;
      state.isShowDailyQueue = initialState.isShowDailyQueue;
      state.prevToken = null;
      state.nextToken = null;
      state.virtualPage = 1;
      state.orderBy = INITIAL_ORDER_BY;
      saveState(state);
    },
    setVirtualPage: (state, { payload }) => {
      state.virtualPage = payload;
      saveState(state);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agencyAccountsApi.endpoints.fetchWorklistAccounts.matchFulfilled,
      (state, { payload }) => {
        state.uniqueDebtors = payload.results.reduce((acc, account) => {
          if (!acc.includes(account.debtorId)) {
            acc.push(account.debtorId);
          }
          return acc;
        }, []);
        state.filteredAccounts = payload.results;
        state.resultCount = payload.meta.resultCount;
        state.resultPrevToken = payload.meta.prevToken;
        state.resultNextToken = payload.meta.nextToken;
        saveState(state);
      },
    );
    builder.addMatcher(
      agencyAccountsApi.endpoints.fetchAccounts.matchFulfilled,
      (state, { payload }) => {
        state.filteredAccounts = payload.results;
        state.resultCount = payload.meta.resultCount;
        state.resultPrevToken = payload.meta.prevToken;
        state.resultNextToken = payload.meta.nextToken;
        saveState(state);
      },
    );

    builder.addMatcher(
      creditorAccountsApi.endpoints.fetchCreditorPortalAccounts.matchFulfilled,
      (state, { payload }) => {
        state.filteredAccounts = payload.results;
        state.resultCount = payload.meta.resultCount;
        state.resultPrevToken = payload.meta.prevToken;
        state.resultNextToken = payload.meta.nextToken;
        saveState(state);
      },
    );

    builder.addMatcher(
      creditorAccountsApi.endpoints.fetchCreditorPortalWorklistAccounts.matchFulfilled,
      (state, { payload }) => {
        state.filteredAccounts = payload.results;
        state.resultCount = payload.meta.resultCount;
        state.resultPrevToken = payload.meta.prevToken;
        state.resultNextToken = payload.meta.nextToken;
        saveState(state);
      },
    );
  },
});

export const selectAccountsTableSlice = (state) => state.accountsTable;

export const {
  setDailyQueueAccounts,
  setPriorityQueueAccounts,
  setSelectedRows,
  selectRows,
  setFilterMinAccountBalance,
  setFilterMaxAccountBalance,
  setFilterFollowUpDates,
  setFilterUploadDates,
  setFilterIncludeTurnoverDates,
  setFilterExcludeTurnoverDates,
  setFilterIncludeLastCallDates,
  setFilterExcludeLastCallDates,
  setFilterExcludeStatusCodes,
  setFilterIncludeStatusCodes,
  setFilterExcludeStatusExternalNames,
  setFilterIncludeStatusExternalNames,
  setPagination,
  setOrderBy,
  resetFilters,
  setVirtualPage,
  setHasLawsuit,
  setCollectionDebtTypes,
  setAssigneeIds,
  setDebtorStates,
  setDebtorTagIds,
  setCreditorIds,
  setCreditorTagIds,
  setWorklistId,
} = accountsTableSlice.actions;

export default accountsTableSlice.reducer;

import { WHITELABEL_SETTINGS } from "common/constants";
import { GlobalNotFoundPage } from "pages/errorPages";
import { createContext, useContext, useMemo } from "react";

const DEFAULT_CONTEXT_VALUE = {
  isError: false,
  isWhiteLabeled: false,
  logo: "",
  slug: "",
  displayName: "",
  signupProps: {
    preTransform: (values) => values,
  },
};

const WhiteLabelContext = createContext(DEFAULT_CONTEXT_VALUE);
WhiteLabelContext.displayName = "WhiteLabelContext";

function findWhitelabelSettings(slug) {
  return WHITELABEL_SETTINGS.find((whitelabel) => whitelabel.slug === slug);
}

function WhiteLabelProvider({ children }) {
  // location hostname is either "localhost" or "app.getaktos.com"
  const location = window.location.hostname;

  const whiteLabelConfigs = useMemo(() => {
    const parts = location.split(".");
    const firstPart = parts[0];
    const lastPart = parts[parts.length - 1];

    // There are few options for the hostname:
    // 1. localhost - local development
    // 2. ncs.localhost - whitelabeled local development for NCSPlus
    // 2. app.getaktos.com - production
    // 3. dev.getaktos.com - staging
    // 4. ncs.dev.getaktos.com - whitelabeled staging for NCSPlus
    // 5. ncs.app.getaktos.com - whitelabeled production agency portal for NCSPlus
    // 6. ncs.portal.getaktos.com - whitelabeled production consumer portal for NCSPlus

    // if the hostname is localhost, we don't need to check for whitelabel
    if (location === "localhost") {
      return DEFAULT_CONTEXT_VALUE;
    }

    // if last part is localhost, check if whitelabel exists or not
    if (parts.length === 2 && lastPart === "localhost") {
      const whiteLabelSettings = findWhitelabelSettings(firstPart);
      if (!whiteLabelSettings) {
        return {
          ...DEFAULT_CONTEXT_VALUE,
          isError: true,
        };
      }
      return {
        ...DEFAULT_CONTEXT_VALUE,
        ...whiteLabelSettings,
        isWhiteLabeled: true,
      };
    }

    // if the hostname is getaktos.com, run some checks
    if (location.split("getaktos.com").length === 2) {
      // if the hostname is app.getaktos.com or dev.getaktos.com or portal.getaktos.com
      if (parts.length === 3) {
        return DEFAULT_CONTEXT_VALUE;
      }

      // if the hostname is ncs.app.getaktos.com or ncs.dev.getaktos.com or ncs.portal.getaktos.com
      if (parts.length === 4) {
        const whiteLabelSettings = findWhitelabelSettings(firstPart);
        if (!whiteLabelSettings) {
          return {
            ...DEFAULT_CONTEXT_VALUE,
            isError: true,
          };
        }
        return {
          ...DEFAULT_CONTEXT_VALUE,
          ...whiteLabelSettings,
          isWhiteLabeled: true,
        };
      }
    }

    return {
      ...DEFAULT_CONTEXT_VALUE,
      isError: true,
    };
  }, [location]);

  if (whiteLabelConfigs.isError) {
    return <GlobalNotFoundPage />;
  }

  return (
    <WhiteLabelContext.Provider value={whiteLabelConfigs}>{children}</WhiteLabelContext.Provider>
  );
}

export default WhiteLabelProvider;

export function useWhiteLabel() {
  const context = useContext(WhiteLabelContext);
  if (context === undefined) {
    throw new Error(`useWhiteLabel must be used within a WhiteLabelContext.Provider`);
  }
  return context;
}

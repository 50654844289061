import { Checkbox, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";

import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useCreateCommunicationLimitMutation,
  useUpdateCommunicationLimitMutation,
} from "features/communicationLimitsTable/communicationLimitsApi";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-right: 12px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default function CommunicationLimitsModal({ record, title, open, onCancel }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createCommunicationLimit, { isLoading: createLoading }] =
    useCreateCommunicationLimitMutation();
  const [updateCommunicationLimit, { isLoading: updateLoading }] =
    useUpdateCommunicationLimitMutation();

  const onModalCancel = () => {
    onCancel();
    form.resetFields();
  };

  const onModalOk = async () => {
    const values = await form.validateFields();
    const payload = {
      ...values,
      id: record?.id,
      states: values.isAllStates ? [] : values.states,
      callResults: constants?.callResults
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      mailResults: constants?.mailResults
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      communicationResults: constants.communicationResults
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
    };
    const result = record
      ? await updateCommunicationLimit(payload)
      : await createCommunicationLimit({
          id: record?.id,
          ...payload,
        });

    if ("data" in result) {
      message.success(`Communication limit rule ${record ? "updated" : "created"} successfully`);
      onModalCancel();
    }
  };

  const initialValues = {
    ...record,
    states: record?.isAllStates ? [] : record?.states,
    resultTags: [
      ...(record?.callResults ?? []),
      ...(record?.mailResults ?? []),
      ...(record?.communicationResults ?? []),
    ],
  };

  return (
    <Modal
      confirmLoading={createLoading || updateLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onModalOk}
      onCancel={onModalCancel}
    >
      <StyledForm
        initialValues={initialValues}
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item rules={[{ required: true }]} label="Name" name="name">
          <Input />
        </Form.Item>
        <Row align="bottom">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.isAllStates !== currentValues?.isAllStates
            }
          >
            {({ getFieldValue }) => {
              const checked = getFieldValue("isAllStates");
              return (
                <StyledFormItem
                  rules={[{ required: !checked }]}
                  name="states"
                  label="Debtor Location(s)"
                >
                  <Select
                    maxTagCount={1}
                    popupMatchSelectWidth={false}
                    disabled={checked}
                    mode="multiple"
                    options={constants?.states.map((state) => ({
                      value: state.value,
                      label: state.display,
                    }))}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
          <Form.Item valuePropName="checked" name="isAllStates">
            <Checkbox>All</Checkbox>
          </Form.Item>
        </Row>
        <Form.Item rules={[{ required: true }]} name="maxAllowed" label="# Contacts Allowed">
          <StyledInputNumber min="0" step="1" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="contactMethods" label="Contact Methods">
          <Select
            mode="multiple"
            popupMatchSelectWidth={false}
            options={constants?.contactMethods.map((method) => ({
              label: method.display,
              value: method.value,
            }))}
          />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="resultTags" label="Result Tags">
          <Select
            mode="multiple"
            popupMatchSelectWidth={false}
            options={[
              {
                label: "Call Results",
                options:
                  constants?.callResults?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Mail Results",
                options:
                  constants?.mailResults?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Communication Results",
                options:
                  constants?.communicationResults?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="rollingPeriod"
          label="Rolling Time Period (days)"
        >
          <StyledInputNumber min="0" step="1" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

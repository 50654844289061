import api, { transformCursorResponse, transformRequest, transformResponse } from "services/api";

export const creditorApi = api
  .enhanceEndpoints({
    addTagTypes: ["UserCreditor"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUserCreditor: build.query({
        query: () => `/api/v1/creditor/creditor-settings/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) =>
          result
            ? // successful query
              [{ type: "UserCreditor", id: result.id }]
            : [],
      }),
      getCreditorPortalCreditor: build.query({
        query: ({ creditorId }) => `/api/v1/creditor/creditors/${creditorId}`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) =>
          result
            ? // successful query
              [{ type: "CreditorPortalCreditors", id: result.id }]
            : [],
      }),
      fetchCreditorPortalCreditors: build.query({
        query: (payload) => ({
          url: "/api/v1/creditor/creditors/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformCursorResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "CreditorPortalCreditors", id })),
                { type: "CreditorPortalCreditors", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalCreditors', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalCreditors", id: "LIST" }],
      }),
      fetchCreditorPortalCreditorSummaries: build.query({
        query: (payload) => ({
          url: "/api/v1/creditor/creditors/summaries/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: (response) =>
          response?.results
            ? // successful query
              [
                ...response.results.map(({ id }) => ({ type: "CreditorPortalCreditors", id })),
                { type: "CreditorPortalCreditors", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorPortalCreditors', id: 'LIST' }` is invalidated
              [{ type: "CreditorPortalCreditors", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetUserCreditorQuery,
  useGetCreditorPortalCreditorQuery,
  useLazyFetchCreditorPortalCreditorsQuery,
  useFetchCreditorPortalCreditorsQuery,
  useFetchCreditorPortalCreditorSummariesQuery,
} = creditorApi;

import { Form, Input, Modal, Select, Tag } from "antd";
import { idToObjectMap } from "common/utils";
import { TableFormList } from "components/formList";
import {
  useAddFeePlanRuleMutation,
  useAddFeePlanRuleSetMutation,
  useDeleteFeePlanRuleMutation,
  useFetchFeePlanRulesQuery,
  useFetchFeePlansQuery,
  useUpdateFeePlanRuleMutation,
  useUpdateFeePlanRuleSetMutation,
} from "features/feePlans/feePlansAPI";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchWorkflowStatesQuery } from "features/workflows/workflowStatesAPI";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddFeePlanRulesetModal({
  title,
  open,
  onOk,
  onCancel,
  selectedRuleSet,
  setSelectedRuleSet,
}) {
  const [form] = Form.useForm();
  const { data: feePlans } = useFetchFeePlansQuery();
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const { data: defaultWorkflowStates } = useFetchWorkflowStatesQuery();
  const [addFeePlanRule] = useAddFeePlanRuleMutation();
  const [updateFeePlanRule] = useUpdateFeePlanRuleMutation();
  const [addFeePlanRuleSet] = useAddFeePlanRuleSetMutation();
  const [updateFeePlanRuleSet] = useUpdateFeePlanRuleSetMutation();
  const [deleteFeePlanRule] = useDeleteFeePlanRuleMutation();

  const initialValues = {
    ...selectedRuleSet,
    defaultFeePlanId: selectedRuleSet?.defaultFeePlan?.id,
  };

  const isNew = !selectedRuleSet;

  const { data: feePlanRules } = useFetchFeePlanRulesQuery(
    {
      ruleSetId: selectedRuleSet?.id,
    },
    { skip: isNew },
  );

  const productOfferingsById = useMemo(() => {
    return idToObjectMap(productOfferings);
  }, [productOfferings]);

  const save = async () => {
    const fields = await form.validateFields();
    const action = isNew
      ? addFeePlanRuleSet(fields)
      : updateFeePlanRuleSet({ ...fields, id: selectedRuleSet.id });
    const result = await action;

    if (isNew && "data" in result) {
      setSelectedRuleSet(result.data);
    }
    return result;
  };

  const onSubmit = async () => {
    const result = await save();
    if ("data" in result) {
      onOk();
    }
  };

  // We run this hook when Add Fee Plan Rule is pressed
  const preAddFeePlanRowHook = async () => {
    if (isNew) {
      const result = await save();
      if ("error" in result) {
        return false;
      }
    }
  };

  const columns = [
    {
      dataIndex: "feePlanId",
      key: "feePlanId",
      render: (index, rule) =>
        feePlans?.find((feePlan) => feePlan.id === rule.feePlanId)?.name ??
        `Fee Plan #${rule.feePlanId}`,
      editable: true,
      inputType: "select",
      title: "Fee Plan",
      inputProps: {
        showSearch: true,
        filterOption,
      },
      options: feePlans?.map((feePlan) => ({ label: feePlan.name, value: feePlan.id })),
    },
    {
      title: "Account Status",
      dataIndex: "workflowStateIds",
      key: "workflowStateIds",
      editable: true,
      inputType: "select",
      inputProps: {
        mode: "multiple",
        filterOption,
      },
      options: defaultWorkflowStates?.map((state) => ({
        value: state.id,
        label: [state.code, state.name].join(" - "),
      })),
      render: (index, rule) => {
        const workflowStates =
          defaultWorkflowStates?.filter((state) => rule.workflowStateIds.includes(state.id)) ?? [];

        return (
          workflowStates?.map((state) => (
            <Tag>
              {state.code} - {state.name}
            </Tag>
          )) ?? "-"
        );
      },
    },
    {
      title: "Legal Status",
      dataIndex: "isLegal",
      key: "isLegal",
      editable: true,
      inputType: "select",
      inputProps: {
        showSearch: true,
        filterOption,
      },
      options: [
        { label: "-------", value: null, key: "null" },
        { label: "Contains Legal", value: true, key: "yes" },
        { label: "No Legal", value: false, key: "no" },
      ],
      render: (index, rule) =>
        ({
          true: <Tag color="red">Contains Legal</Tag>,
          false: <Tag color="green">No Legal</Tag>,
          null: null,
        }[rule.isLegal]),
    },
    {
      title: "Product",
      dataIndex: "productOfferingId",
      key: "productOfferingId",
      editable: true,
      inputType: "select",
      inputProps: {
        showSearch: true,
        filterOption,
      },
      options: productOfferings?.map((productOffering) => ({
        label: productOffering.name,
        value: productOffering.id,
      })),
      render: (index, rule) => productOfferingsById[rule.productOfferingId]?.name,
    },
    {
      dataIndex: "minAccountBalance",
      key: "minAccountBalance",
      editable: true,
      inputType: "number",
      inputProps: {
        step: 0.01,
      },
      title: "Account Original Balance (From)",
    },
    {
      dataIndex: "maxAccountBalance",
      key: "maxAccountBalance",
      editable: true,
      inputType: "number",
      inputProps: {
        step: 0.01,
      },
      title: "Account Original Balance (To)",
    },
    {
      dataIndex: "minAccountServiceAgeDays",
      key: "minAccountServiceAgeDays",
      editable: true,
      required: false,
      inputType: "number",
      title: "Days Since Service Date (From)",
    },
    {
      dataIndex: "maxAccountServiceAgeDays",
      key: "maxAccountServiceAgeDays",
      required: false,
      editable: true,
      inputType: "number",
      title: "Days Since Service Date (To)",
    },
    {
      dataIndex: "minAccountAgeDays",
      key: "minAccountAgeDays",
      editable: true,
      required: false,
      inputType: "number",
      title: "Days Since Upload (From)",
    },
    {
      dataIndex: "maxAccountAgeDays",
      key: "maxAccountAgeDays",
      required: false,
      editable: true,
      inputType: "number",
      title: "Days Since Upload (To)",
    },
  ];

  return (
    <Modal
      width={1200}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="defaultFeePlanId"
          label="Default Fee Plan"
          tooltip="The fee plan to use if no fee plan rules are matched."
        >
          <Select
            showSearch
            popupMatchSelectWidth={false}
            filterOption={filterOption}
            placeholder="Select Fee Plan"
            options={feePlans?.map((feePlan) => ({ label: feePlan.name, value: feePlan.id }))}
          />
        </Form.Item>
      </StyledForm>
      <h5>Fee Plan Rules</h5>
      Define fee plan rules, which define which fee plan to use for each account criteria matched.
      <br />
      <br />
      <TableFormList
        addText="Add Rule"
        columns={columns}
        data={isNew ? [] : feePlanRules}
        onDelete={(feePlanRule) =>
          deleteFeePlanRule({
            ruleSetId: selectedRuleSet.id,
            ...feePlanRule,
          })
        }
        onSave={async (feePlanRule) => {
          const action = feePlanRule?.id ? updateFeePlanRule : addFeePlanRule;
          return action({
            ruleSetId: selectedRuleSet.id,
            ...feePlanRule,
          });
        }}
        preAddRowHook={preAddFeePlanRowHook}
      />
    </Modal>
  );
}

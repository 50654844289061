import { Form, Input, Modal, Select, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import {
  useCreateEmployerMutation,
  useUpdateEmployerMutation,
} from "features/employersTable/employersAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function EmployerModal({ title, onOk, onCancel, selectedEmployer }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [addEmployer] = useCreateEmployerMutation();
  const [updateEmployer] = useUpdateEmployerMutation();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onSubmit = async () => {
    const fields = await form.validateFields();
    const result = selectedEmployer
      ? await updateEmployer({ ...fields })
      : await addEmployer({ ...fields });

    if ("data" in result) {
      message.success("Employer saved successfully.");
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error(`Failed to save employer. Please try again later.`);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      okText="Submit"
      open
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={selectedEmployer}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item name="code" label="Employer Code" rules={[{ required: true }]}>
          <Input placeholder="Enter Employer Code..." />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input placeholder="Enter Name..." />
        </Form.Item>
        <Form.Item name="ein" label="EIN" rules={[{ required: true }]}>
          <Input placeholder="Enter EIN..." />
        </Form.Item>
        <Form.Item name="website" label="Website" rules={[{ required: true }]}>
          <Input placeholder="Enter Website..." />
        </Form.Item>
        <Form.Item
          name={["address", "address1"]}
          label="Address Line 1"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter Address..." />
        </Form.Item>
        <Form.Item name={["address", "address2"]} label="Address Line 2">
          <Input placeholder="Enter Address..." />
        </Form.Item>
        <Form.Item
          name={["address", "city"]}
          label="City"
          rules={[
            { pattern: /^[^\d]*$/, message: "Please enter a valid city" },
            { required: true },
          ]}
        >
          <Input placeholder="Enter City..." />
        </Form.Item>
        <Form.Item name={["address", "state"]} label="State" rules={[{ required: true }]}>
          <Select
            placeholder="Select one..."
            options={constants?.states.map((state) => ({
              label: state.display,
              value: state.value,
            }))}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name={["address", "zipCode"]}
          label="Zip Code"
          rules={[
            {
              required: true,
              pattern: /^\d{5}(-\d{4})?$/,
              message: "Must be a valid zip code",
            },
          ]}
        >
          <Input placeholder="Enter Zip Code..." maxLength={5} />
        </Form.Item>
        <Form.Item name={["contact", "name"]} label="Contact Name" rules={[{ required: true }]}>
          <Input placeholder="Enter Contact Name..." />
        </Form.Item>
        <Form.Item name={["contact", "workPhone"]} label="Work Phone">
          <Input placeholder="XXX-XXX-XXXX" />
        </Form.Item>
        <Form.Item name={["contact", "cellPhone"]} label="Cell Phone">
          <Input placeholder="XXX-XXX-XXXX" />
        </Form.Item>
        <Form.Item name={["contact", "email"]} label="Email">
          <Input placeholder="Enter Email..." />
        </Form.Item>
        <Form.Item name="notes" label="Notes / Description">
          <Input placeholder="Enter Notes..." />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

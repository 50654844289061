import { Form, Input, Modal, Radio, Select, Space, Typography, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { populateKey } from "common/utils";
import EditList from "features/workflows/components/editList";
import { getTriggerOptions } from "features/workflows/util";
import { useFetchSchemaQuery } from "features/workflows/workflowAPI";
import {
  useAddWorkflowEventMutation,
  useUpdateWorkflowEventMutation,
} from "features/workflows/workflowEventsAPI";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 5 },
  },
};

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddWorkflowEventModal({ onOk, onCancel, selectedWorkflowEvent }) {
  const { workflowId } = useParams();
  const [form] = Form.useForm();
  const [workflowActions, setWorkflowActions] = useState([]);
  const [triggerConditions, setTriggerConditions] = useState([]);
  const [conditionEditKey, setConditionEditKey] = useState(null);
  const [actionEditKey, setActionEditKey] = useState(null);
  const isNew = !selectedWorkflowEvent;

  const [addWorkflowEvent, { isLoading: addEventLoading }] = useAddWorkflowEventMutation();
  const [updateWorkflowEvent, { isLoading: updateEventLoading }] = useUpdateWorkflowEventMutation();
  const { data: schema = {}, isLoading: isSchemaLoading } = useFetchSchemaQuery();

  useEffect(() => {
    if (selectedWorkflowEvent) {
      // Set form values
      form.setFieldsValue(selectedWorkflowEvent);
      // Set actions, ensures there is key for each condition
      setWorkflowActions(populateKey(selectedWorkflowEvent.actions, () => Math.random()));
      // Set And/Or selector and triggerConditions
      form.setFieldValue("filterConditions", selectedWorkflowEvent.conditions?.func);
      setTriggerConditions(
        // ensures there is key for each condition
        populateKey(selectedWorkflowEvent.conditions?.args, () => Math.random()),
      );
    }
  }, [form, selectedWorkflowEvent]);

  const handleTriggerTypeOnChange = (value) => {
    if (value) {
      setWorkflowActions([]);
      setTriggerConditions([]);
      setConditionEditKey(null);
      setActionEditKey(null);
    }
  };

  const isLoading = addEventLoading || updateEventLoading || isSchemaLoading;

  const triggerOptions = useMemo(() => getTriggerOptions(schema.triggers), [schema]);

  const onSubmit = async () => {
    const values = await form.validateFields();

    const payload = {
      // required for get parameter
      workflowId,
      // form values: name, description, triggerEventType
      ...values,
      actions: workflowActions,
      // conditions: { func: and, conditions: [] }
      conditions: {
        func: values.filterConditions ?? "and",
        args: triggerConditions ?? [],
      },
    };

    const action = isNew
      ? addWorkflowEvent(payload)
      : updateWorkflowEvent({ ...payload, id: selectedWorkflowEvent.id });
    const result = await action;

    if ("data" in result) {
      const successMessage = isNew
        ? "Workflow event added successfully!"
        : "Workflow event updated successfully!";
      message.success(successMessage);
      onOk();
    }

    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title={`${isNew ? "Add" : "Edit"} Workflow Event`}
      open
      onOk={onSubmit}
      onCancel={onCancel}
      okText={isNew ? "Create" : "Update"}
      okButtonProps={{
        loading: isLoading,
        disabled:
          // disable if no actions are present
          workflowActions.length === 0 ||
          // disable if any of the actions or conditions are new
          triggerConditions.findIndex((c) => c.isNew) !== -1 ||
          workflowActions.findIndex((w) => w.isNew) !== -1,
      }}
      width="90%"
    >
      <Form
        form={form}
        labelAlign="left"
        validateMessages={{ required: "This is a required field" }}
        onFinish={onSubmit}
        initialValues={{ filterConditions: "and" }}
        {...formItemLayout}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input placeholder="Enter name..." />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description..." />
        </Form.Item>
        <Form.Item
          name="triggerEventType"
          label="Select a trigger type"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Select
            showSearch
            filterOption={filterOption}
            options={triggerOptions}
            placeholder="Select a trigger type..."
            onChange={handleTriggerTypeOnChange}
          />
        </Form.Item>
        <Typography.Paragraph>Define trigger conditions for trigger.</Typography.Paragraph>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.triggerEventType !== currentValues.triggerEventType
          }
        >
          {({ getFieldValue }) => {
            const trigger = getFieldValue("triggerEventType");
            return (
              <>
                <EditList
                  editKey={conditionEditKey}
                  setEditKey={setConditionEditKey}
                  trigger={trigger}
                  cardType="condition"
                  items={triggerConditions}
                  setItems={setTriggerConditions}
                />
                {triggerConditions?.length >= 2 && (
                  <Form.Item
                    name={["filterConditions"]}
                    label="Filter Conditions"
                    required={triggerConditions?.length > 0}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="and">All of the conditions are met (AND)</Radio>
                        <Radio value="or">Any of the conditions are met (OR)</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
        <Typography.Paragraph>Define actions for workflow event.</Typography.Paragraph>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.triggerEventType !== currentValues.triggerEventType
          }
        >
          {({ getFieldValue }) => {
            const trigger = getFieldValue("triggerEventType");
            return (
              <EditList
                editKey={actionEditKey}
                setEditKey={setActionEditKey}
                trigger={trigger}
                cardType="action"
                items={workflowActions}
                setItems={setWorkflowActions}
              />
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

import api, { transformRequest, transformResponse } from "services/api";

export const paymentsApi = api
  .enhanceEndpoints({ addTagTypes: ["PaymentIntents", "PaymentTransactions", "DebtorAccounts"] })
  .injectEndpoints({
    endpoints: (build) => ({
      createCardPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-methods/create-card-from-number/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      createAchPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-methods/create-ach-from-number/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      createLoggingPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-methods/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      getAccountPaymentSettings: build.query({
        query: (payload) =>
          `/api/v1/core/accounts/payment-settings/?account_id=${payload.accountIds}`,
        transformResponse: (response) => transformResponse(response),
        // providesTags: (result) => [{ type: "Invoices", id: result.id }],
      }),
      getPaymentMethodOptions: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/payment-methods/`,
        transformResponse: (response) => transformResponse(response),
        // providesTags: (result) => [{ type: "Invoices", id: result.id }],
      }),
      getPaymentIntents: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/payment-intents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "PaymentIntents", id: "LIST" }],
      }),
      getPaymentTransactions: build.query({
        query: (payload) =>
          `/api/v1/core/debtors/${payload.debtorId}/payment-transactions/?payment_intent_id=${payload.paymentIntentId}`,
        transformResponse: (response) => transformResponse(response),
        providesTags: () => [{ type: "PaymentTransactions", id: "LIST" }],
      }),
      createPaymentIntents: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      refundPaymentIntent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/${payload.paymentIntentId}/refund/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "PaymentTransactions", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      voidPaymentIntent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/${payload.paymentIntentId}/void/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "PaymentTransactions", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      bulkDeletePaymentIntents: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/bulk-delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      bulkDeleteScheduledPaymentIntents: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/bulk-delete-scheduled-payments/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      unsettlePaymentIntent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/${payload.paymentIntentId}/undo-settlement/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      executePaymentIntent: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/${payload.paymentIntentId}/execute/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: () => [
          { type: "PaymentIntents", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
        ],
      }),
      calculateBalanceFromSchedule: build.query({
        query: (payload) => ({
          url: `/api/v1/core/debtors/${payload.debtorId}/payment-intents/calculate-balance-from-schedule/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateAchPaymentMethodMutation,
  useCreateCardPaymentMethodMutation,
  useCreateLoggingPaymentMethodMutation,
  useCreatePaymentIntentsMutation,
  useBulkDeletePaymentIntentsMutation,
  useBulkDeleteScheduledPaymentIntentsMutation,
  useExecutePaymentIntentMutation,
  useGetAccountPaymentSettingsQuery,
  useGetPaymentIntentsQuery,
  useGetPaymentMethodOptionsQuery,
  useGetPaymentTransactionsQuery,
  useRefundPaymentIntentMutation,
  useUnsettlePaymentIntentMutation,
  useVoidPaymentIntentMutation,
  useLazyCalculateBalanceFromScheduleQuery,
} = paymentsApi;

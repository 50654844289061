import { Button, Form, Input, InputNumber, message, Row, Select } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { parseCardExpirationDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { useUserType } from "features/auth";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useCreateCreditorPortalLoggingPaymentMethodMutation } from "features/payments/creditorPortal/paymentsAPI";
import {
  useCreateAchPaymentMethodMutation,
  useCreateCardPaymentMethodMutation,
  useCreateLoggingPaymentMethodMutation,
} from "features/payments/paymentsAPI";
import { selectPaymentsSlice } from "features/payments/paymentsSlice";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledInput = styled(Input)`
  border-bottom: 1px solid grey;
  width: 200px;
  border-radius: 0px;
  &:hover,
  &:focus {
    border-bottom: 1px solid grey;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-left: 4px;
  margin-right: 4px;
`;

const StyledDatePicker = styled(AktDatePicker)`
  &.ant-picker {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid grey;
  }
`;

const StyledFormItemContainer = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledSelect = styled(Select)`
  border-bottom: 1px solid grey;
  & .ant-select-selector {
    margin-bottom: -2px;
  }
  &:hover,
  &:focus {
    border-bottom: 1px solid grey;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function CreatePaymentMethodForm({ submitButtonText, confirmPaymentLoading, onOk, onCancel }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { debtorId } = useParams();

  const [form] = Form.useForm();
  const paymentsSlice = useSelector(selectPaymentsSlice);

  const [createAchPaymentMethod, { isLoading: isLoadingCreateAch }] =
    useCreateAchPaymentMethodMutation();
  const [createCardPaymentMethod, { isLoading: isLoadingCreateCard }] =
    useCreateCardPaymentMethodMutation();
  const [createAgencyLoggingPaymentMethod, { isLoading: isLoadingAgencyCreateLogging }] =
    useCreateLoggingPaymentMethodMutation();
  const [
    createCreditorPortalLoggingPaymentMethod,
    { isLoading: isLoadingCreditorPortalCreateLogging },
  ] = useCreateCreditorPortalLoggingPaymentMethodMutation();
  const [createLoggingPaymentMethod, isLoadingCreateLogging] = isAgencyUserType
    ? [createAgencyLoggingPaymentMethod, isLoadingAgencyCreateLogging]
    : [createCreditorPortalLoggingPaymentMethod, isLoadingCreditorPortalCreateLogging];

  const accountTypeOptions = [
    { value: "checking", label: "Checking" },
    { value: "saving", label: "Saving" },
  ];

  const { data: constants } = useFetchBackendConstantsQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const { data: me } = useFetchMeQuery();
  const defaults = useMemo(() => {
    const paymentMethodOptions = [];
    let initialPaymentMethodOption = me?.enabledPaymentMethods?.defaultPaymentMethod;
    let processPaymentOptions = me?.enabledPaymentMethods?.enabledProcessedPaymentOptions || [];
    let loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    if (paymentsSlice.paidTo === "creditor") {
      loggingPaymentOptions = isCreditorUserType
        ? me?.enabledPaymentMethods?.enabledPaidToCreditorPortalPaymentOptions
        : me?.enabledPaymentMethods?.enabledPaidToCreditorPaymentOptions;
      processPaymentOptions = []; // These payments were already paid to the client/creditor, so we don't need to process them again.
      initialPaymentMethodOption = "paid_to_creditor";
    } else if (paymentsSlice.paidTo === "forwarding_entity") {
      loggingPaymentOptions =
        me?.enabledPaymentMethods?.enabledPaidToForwardingEntityPaymentOptions;
      processPaymentOptions = []; // These payments were already paid to a forwarding entity, so we don't need to process them again.
      initialPaymentMethodOption = "forwarding_entity";
    } else if (paymentsSlice.isPaymentPlan) {
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    } else if (moment(paymentsSlice.paymentsSchedule[0].date).unix() > moment().unix()) {
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    } else {
      // NOTE: This case should only happen when the payment is scheduled for today.
      // Right now they are unable to schedule payments in the past (see shouldDisableDate). This was intentionally
      // asked by CBY. We may choose to add permissioning around this in the future, so leaving this here.
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    }

    // Categorize payment methods into process and logging options.
    // e.g
    // paymentMethodOptions = [
    //   {
    //     label: "Process options",
    //     options: [
    //       { value: "process_card", label: "Card" },
    //       { value: "process_ach", label: "ACH" },
    //     ],
    //   },
    //   {
    //     label: "Logging options",
    //     options: [
    //       { value: "card", label: "Card" },
    //       { value: "ach", label: "ACH" },
    //       { value: "check", label: "Check" },
    //       { value: "cash", label: "Cash" },
    //       { value: "bankruptcy", label: "Bankruptcy Payment" },
    //       { value: "online_portal", label: "Online Portal" },
    //       { value: "legal", label: "Legal" },
    //       { value: "other", label: "Other" },
    //     ],
    //   },
    // ];
    if (processPaymentOptions.length > 0) {
      paymentMethodOptions.push({
        label: "Process options",
        options: processPaymentOptions,
      });
    }
    if (loggingPaymentOptions.length > 0) {
      paymentMethodOptions.push({
        label: "Logging options",
        options: loggingPaymentOptions,
      });
    }

    return { paymentMethodOptions, initialPaymentMethodOption };
  }, [
    paymentsSlice.paidTo,
    paymentsSlice.isPaymentPlan,
    paymentsSlice.paymentsSchedule,
    isCreditorUserType,
    me?.enabledPaymentMethods,
  ]);

  const initialValues = {
    paymentMethodType: defaults.initialPaymentMethodOption,
  };

  const onCreateNewPaymentMethodFinish = async (values) => {
    const { paymentMethodType, emails, referenceNumber, promiseToPayDate, promiseToPayLastDate } =
      values;
    const emailsArray = emails?.split(",").map((email) => email.trim());

    const action = {
      process_card: () => {
        const { expirationDate } = values;
        const [cardExpMonth, cardExpYear] = parseCardExpirationDate(expirationDate);
        return createCardPaymentMethod({
          ...values,
          paymentMethodType,
          debtorId,
          cardExpMonth,
          cardExpYear,
          emails: emailsArray,
          // TODO make this configurable via agency settings
          paymentProvider: "repay",
        });
      },
      process_ach: () =>
        createAchPaymentMethod({
          ...values,
          paymentMethodType,
          debtorId,
          emails: emailsArray,
          // TODO make this configurable via agency settings
          paymentProvider: "repay",
        }),
      card: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      ach: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      check: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      cash: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      other: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      online_portal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      paid_to_creditor: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      paid_to_creditor_legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      // HACK: For now, we're just going to always create cash payment methods
      // Later, we will refactor this to either use the correct endpoint,
      // or retrieve the dynamic list of logging payment method options.
      forwarding_entity: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      forwarding_entity_legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      cashiers_check: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      money_order: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      third_party: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      bankruptcy: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      promise_to_pay: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
    }[paymentMethodType];

    const result = await action();
    if ("data" in result) {
      return onOk(result.data.id, referenceNumber, promiseToPayDate, promiseToPayLastDate);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error("Failed to process the payment. Please refresh and try again.");
    }
  };

  const billingAddressFormFields = (
    <>
      <h3>Billing Details</h3>
      <StyledFormItemContainer>
        <Row>
          <StyledFormItem label="First Name" name="firstName" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="First Name" />
          </StyledFormItem>
          <StyledFormItem label="Last Name" name="lastName" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="Last Name" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <Row>
          <StyledFormItem label="Address1" name="address1" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="Address 1" />
          </StyledFormItem>
          <StyledFormItem label="Address2" name="address2">
            <StyledInput bordered={false} maxLength={100} placeholder="Address 2" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <Row wrap={false}>
          <StyledFormItem label="City" name="city" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="City" />
          </StyledFormItem>
          <StyledFormItem label="State" name="state" rules={[{ required: true }]}>
            <StyledSelect
              popupMatchSelectWidth={false}
              bordered={false}
              placeholder="State"
              options={constants?.states.map(({ display, value }) => ({
                value,
                label: display,
              }))}
              filterOption={filterOption}
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            name="zipCode"
            label="Zip Code"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^\d{5}(-\d{4})?$/,
                message: "Must be a valid zip code",
              },
            ]}
          >
            <StyledInput bordered={false} maxLength={5} placeholder="Zip Code" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <StyledFormItem label="Email(s)" name="emails">
          <StyledInput
            bordered={false}
            style={{ width: 250 }}
            maxLength={100}
            placeholder="Emails (comma separated)"
          />
        </StyledFormItem>
      </StyledFormItemContainer>
    </>
  );

  return (
    <StyledForm
      form={form}
      layout="vertical"
      validateMessages={{ required: "This is a required field" }}
      initialValues={initialValues}
      onFinish={onCreateNewPaymentMethodFinish}
    >
      <Form.Item name="paymentMethodType" label="New Payment Method:">
        <Select options={defaults.paymentMethodOptions} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.paymentMethodType !== currentValues.paymentMethodType
        }
      >
        {({ getFieldValue }) => {
          switch (getFieldValue("paymentMethodType")) {
            case "process_card":
              return (
                <>
                  <h3>Card Information</h3>
                  <StyledFormItem
                    name="cardNumber"
                    label="Card Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <StyledInput bordered={false} maxLength={16} placeholder="Card Number" />
                  </StyledFormItem>
                  <StyledFormItem rules={[{ required: true }]}>
                    <Input.Group compact>
                      <Form.Item
                        label="Expiration Date"
                        name="expirationDate"
                        rules={[{ required: true }]}
                      >
                        <StyledDatePicker placeholder="MM/YY" format="MM/YY" />
                      </Form.Item>
                      <StyledFormItem
                        label="CVV"
                        name="cvv"
                        rules={[
                          {
                            pattern: /^[0-9]{3,4}$/,
                            message: "Please enter a valid CVV.",
                          },
                        ]}
                      >
                        <StyledInput bordered={false} placeholder="CVV" maxLength={4} />
                      </StyledFormItem>
                    </Input.Group>
                  </StyledFormItem>
                  {billingAddressFormFields}
                </>
              );
            case "process_ach":
              return (
                <>
                  <Form.Item
                    label="Bank Account Name"
                    name="bankAccountName"
                    rules={[
                      { required: true },
                      {
                        type: "string",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="bankRoutingNumber"
                    label="Routing Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid routing number.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter routing number..."
                      style={{ width: 200 }}
                      maxLength={9}
                    />
                  </Form.Item>
                  <Form.Item
                    name="bankAccountNumber"
                    label="Account Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid account number.",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter account number..."
                      style={{ width: 200 }}
                      maxLength={17}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item name="bankAccountType" label="Account Type" initialValue="checking">
                    <Select options={accountTypeOptions} />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <Input />
                  </Form.Item>
                  {billingAddressFormFields}
                </>
              );
            case "check":
            case "money_order":
            case "cashiers_check":
              return (
                <Form.Item
                  label="Reference Number"
                  name="referenceNumber"
                  rules={[
                    { required: false },
                    {
                      type: "string",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              );
            case "promise_to_pay":
              return (
                <>
                  <Form.Item
                    label="Promise to Pay Date"
                    name="promiseToPayDate"
                    rules={[{ required: false }]}
                  >
                    <StyledDatePicker placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                  </Form.Item>
                  <Form.Item
                    label="Promise Expire Date"
                    name="promiseToPayLastDate"
                    rules={[{ required: false }]}
                  >
                    <StyledDatePicker placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                  </Form.Item>
                </>
              );
            default:
              return null;
          }
        }}
      </Form.Item>
      <Form.Item>
        <Button onClick={onCancel}>Back</Button>
        <Button
          loading={
            isLoadingCreateAch ||
            isLoadingCreateCard ||
            isLoadingCreateLogging ||
            confirmPaymentLoading
          }
          style={{ marginLeft: 8 }}
          type="primary"
          htmlType="submit"
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </StyledForm>
  );
}

export default CreatePaymentMethodForm;

import { Modal, Select, InputNumber, Form, message } from "antd";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { useUpdateBackupDateMutation } from "features/backupDates/backupDatesAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

export default function EditBackupDateModal({ open, backupDate, onOk, onCancel }) {
  const [form] = Form.useForm();
  const [updateBackupDate] = useUpdateBackupDateMutation();
  const { data: constants } = useFetchBackendConstantsQuery();

  const onUpdateBackupDate = async () => {
    const values = await form.validateFields();
    const result = await updateBackupDate({
      offset: values.offset ?? null,
      backupDateField: values.backupDateField ?? null,
      systemDateField: backupDate.systemDateField,
    });
    if ("data" in result) {
      message.success("Backup date updated successfully");
      form.resetFields();
      onOk();
    }
  };

  return (
    <Modal
      open={open}
      title={`Set backup date for ${camelToWords(snakeToCamelCase(backupDate.systemDateField))}`}
      onOk={onUpdateBackupDate}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" initialValues={backupDate}>
        <Form.Item name="backupDateField" label="Backup Date Name">
          <Select
            allowClear
            popupMatchSelectWidth={false}
            options={constants?.accountDateFields
              .map((field) => ({
                label: field.display,
                value: field.value,
              }))
              .filter((field) => field.value !== backupDate.systemDateField)}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.backupDateField !== currentValues.backupDateField
          }
        >
          {({ getFieldValue }) => {
            const backupDateField = getFieldValue("backupDateField");
            if (!backupDateField) return null;
            return (
              <Form.Item
                name="offset"
                label="Days (+/-)"
                rules={[
                  {
                    required: !!backupDateField,
                    message: "This field is required.",
                  },
                ]}
              >
                <InputNumber step={1} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

import { FormWizard } from "components/formWizard";

import AddressesStep from "features/addDebtorsWizard/wizardSteps/addressesStep";
import BaseStep from "features/addDebtorsWizard/wizardSteps/baseStep";
import DebtorCustomFields from "features/addDebtorsWizard/wizardSteps/debtorCustomFields";
import PhonesStep from "features/addDebtorsWizard/wizardSteps/phoneStep";
import { useState } from "react";

function AddDebtorWizard({ onCancel, onDone, debtType }) {
  const [phones, setPhones] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const handleDeletePhone = (phone) => {
    setPhones((prevPhones) => {
      return prevPhones.filter((d) => {
        return d.phoneNumber !== phone.phoneNumber;
      });
    });
  };

  const handleAddPhone = ({ index, ...phone }) => {
    if (index === -1) {
      setPhones((prev) => [...prev, { ...phone, key: Math.random() }]);
    } else {
      setPhones((prev) => {
        const copy = [...prev];
        copy[index] = phone;
        return copy;
      });
    }
    return { data: true };
  };

  const handleDeleteAddress = (address) => {
    setAddresses((prevAddresses) => {
      return prevAddresses.filter((d) => {
        return d.address1 !== address.address1 && d.address2 !== address.address2;
      });
    });
  };

  const handleAddAddress = ({ index, ...address }) => {
    if (index === -1) {
      setAddresses((prev) => [...prev, { ...address, key: Math.random() }]);
    } else {
      setAddresses((prev) => {
        const copy = [...prev];
        copy[index] = address;
        return copy;
      });
    }
    return { data: true };
  };

  const handleOnDone = ({ step0: debtor }) => {
    if (debtor.type === "consumer") {
      debtor.name = `${debtor?.firstName}${debtor?.middleName ? ` ${debtor.middleName}` : ""} ${
        debtor?.lastName
      }`;
    } else {
      debtor.name = debtor.companyName;
    }
    onDone({
      ...debtor,
      phones,
      addresses,
    });
  };

  const validateAddresses = async () => {
    const primaryAddressCounter = addresses.reduce((acc, curr) => {
      if (curr.isPrimary) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (primaryAddressCounter === 0) {
      return { error: new Error("A primary address is required to proceed") };
    }

    if (primaryAddressCounter > 1) {
      return { error: new Error("Only 1 primary address is allowed") };
    }
  };

  const debtorSteps = [
    {
      title: "Debtor Base info",
      form: (
        <>
          <BaseStep debtType={debtType} />
          <DebtorCustomFields />
        </>
      ),
    },
    {
      title: "Addresses",
      form: (
        <AddressesStep
          dataSource={addresses}
          handleDelete={handleDeleteAddress}
          handleSave={handleAddAddress}
        />
      ),
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
      onNext: async () => validateAddresses(),
    },
    {
      title: "Phones",
      form: (
        <PhonesStep
          dataSource={phones}
          handleDelete={handleDeletePhone}
          handleSave={handleAddPhone}
        />
      ),
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
  ];

  return (
    <FormWizard
      canSkipSteps={false}
      steps={debtorSteps}
      onCancel={onCancel}
      onDone={handleOnDone}
    />
  );
}

export default AddDebtorWizard;

import { Form, Select, Tag } from "antd";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchSettlementAndPaymentRuleSetsQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useEffect, useState } from "react";

export default function PaymentConfigurationStep() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: settlementAndPaymentRuleSets } = useFetchSettlementAndPaymentRuleSetsQuery();
  const categories =
    constants?.paymentCategories?.invoiceable.map((category) => ({
      value: category.value,
      label: category.display,
    })) ?? [];
  const [selectedCategoriesPriority, setSelectedCategoriesPriority] = useState(
    categories.reduce((elements, key) => ({ ...elements, [key]: null }), {}),
  );
  const formInstance = Form.useFormInstance();

  useEffect(() => {
    const values = formInstance?.getFieldValue(["paymentConfig", "paymentCategoryPriority"]);
    const priorities = {};
    values?.forEach((categoryValue, index) => {
      priorities[categoryValue] = index + 1;
    });
    setSelectedCategoriesPriority(priorities);
  }, [formInstance, setSelectedCategoriesPriority]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {selectedCategoriesPriority[value]}. {label}
      </Tag>
    );
  };

  const handleChange = (values) => {
    const priorities = {};
    values.forEach((categoryValue, index) => {
      priorities[categoryValue] = index + 1;
    });
    setSelectedCategoriesPriority(priorities);
  };

  return (
    <>
      <Form.Item
        name={["paymentConfig", "paymentCategoryPriority"]}
        label="Payment Category Allocation Priority"
        rules={[{ required: true, message: "This field is required." }]}
        tooltip="Select the payment category order in which a payment will be allocated (e.g. does the payment pay off the legal fees, principal, and interest in that order?)."
      >
        <Select
          placeholder="Select and Order Categories..."
          tagRender={tagRender}
          mode="multiple"
          options={categories}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item
        name={["paymentConfig", "settlementPaymentRulesetId"]}
        label="Payment Ruleset"
        tooltip="Select payment ruleset."
      >
        <Select
          placeholder="Select payment ruleset..."
          options={settlementAndPaymentRuleSets?.map((ruleSet) => ({
            value: ruleSet.id,
            label: ruleSet.name,
          }))}
        />
      </Form.Item>
    </>
  );
}

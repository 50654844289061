import { Form, Select } from "antd";
import styled from "styled-components";
import SendDialerCampaignSubForm from "./sendDialerCampaignSubForm";
import SendTextSubForm from "./sendTextSubForm";
import SkipTraceSubForm from "./skipTraceSubForm";

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledCard = styled.div`
  margin-bottom: 32px;
  padding-top: 8px;
`;

const StyledSelect = styled(Select)`
  max-width: 400px !important;
  margin-right: 4px;
`;

export default function DebtorOperationsStep({ setHideParentModal }) {
  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <StyledCard>
      <StyledHeader>Apply an operation to the debtors in bulk: </StyledHeader>
      <Form.Item
        name={["operations", "operationType"]}
        label="Operation"
        rules={[
          {
            required: true,
            message: "Please select an operation",
          },
        ]}
      >
        <StyledSelect
          showSearch
          filterOption={filterOption}
          popupMatchSelectWidth={false}
          // Operations: “Send Dialer Campaign”, “Run Skip Trace”, “Send Opt-In Text”, “Opt-Out of Text”, “Send Text Template”, “Send Email Template”
          options={[
            {
              label: "Send Dialer Campaign",
              value: "sendDialerCampaign",
            },
            {
              label: "Run Skip Trace",
              value: "skipTrace",
            },
            {
              label: "Send Text",
              value: "sendText",
            },
            {
              label: "Send Email Template",
              value: "sendEmailTemplate",
            },
          ]}
          placeholder="Select one..."
          allowClear
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, curr) =>
          prev?.operations?.operationType !== curr?.operations?.operationType
        }
      >
        {({ getFieldValue }) => {
          const operationType = getFieldValue(["operations", "operationType"]);

          if (operationType === "sendDialerCampaign") {
            return (
              <SendDialerCampaignSubForm
                setHideParentModal={setHideParentModal}
                operationType={operationType}
              />
            );
          }

          if (operationType === "sendText") {
            return (
              <SendTextSubForm
                setHideParentModal={setHideParentModal}
                operationType={operationType}
              />
            );
          }

          if (operationType === "skipTrace") {
            return <SkipTraceSubForm setHideParentModal={setHideParentModal} />;
          }

          return null;
        }}
      </Form.Item>
    </StyledCard>
  );
}

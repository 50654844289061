import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, message } from "antd";
import { toTitleCase } from "common/utils";
import {
  useDeleteCommunicationMethodMutation,
  useFetchCommunicationMethodsQuery,
} from "features/communicationMethodsTable/communicationMethodsAPI";
import CommunicationMethodModal from "features/communicationMethodsTable/components/communicationMethodModal";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
  cursor: pointer;
`;

export default function SequencesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCommunicationMethod, setSelectedCommunicationMethod] = useState(null);
  const [deleteCommunicationMethod] = useDeleteCommunicationMethodMutation();
  const { data: communicationMethods, isLoading } = useFetchCommunicationMethodsQuery();

  const handleDeleteCommunicationMethod = async ({ communicationMethodId }) => {
    const result = await deleteCommunicationMethod({ communicationMethodId });

    if ("data" in result) {
      message.success("Communication method deleted successfully!");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Letter Code",
      dataIndex: "letterCode",
      key: "letterCode",
      render: (index, record) => record.letterCode ?? "-",
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (index, record) => toTitleCase({ str: record.medium, delimiter: "_" }) ?? "-",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (index, record) => toTitleCase({ str: record.provider, delimiter: "_" }) ?? "-",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            key="edit"
            onClick={() => {
              setSelectedCommunicationMethod(record);
              setModalVisible(true);
            }}
          />
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this communication method?"
            onConfirm={() => handleDeleteCommunicationMethod({ communicationMethodId: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onNewRuleOk = () => {
    setModalVisible(false);
    setSelectedCommunicationMethod(null);
  };

  return (
    <>
      <Row align="middle">
        <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
          Add Communication Method
        </Button>
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={communicationMethods}
      />
      {isModalVisible && (
        <CommunicationMethodModal
          title={`${selectedCommunicationMethod ? "Edit" : "Add"} Communication Method`}
          open={isModalVisible}
          onOk={onNewRuleOk}
          selectedCommunicationMethod={selectedCommunicationMethod}
          onCancel={() => {
            setModalVisible(false);
            setSelectedCommunicationMethod(null);
          }}
        />
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import api, { transformResponse } from "services/api";

export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCreditorPortalWorkflows: build.query({
      query: () => `/api/v1/creditor/workflows/`,
      transformResponse: (response) => transformResponse(response),
    }),
    fetchCreditorPortalWorkflowStateExternalNames: build.query({
      query: (payload) => `/api/v1/creditor/workflow-states/state-external-names/`,
      transformResponse: (response) => transformResponse(response),
    }),
    fetchClientSummary: build.query({
      query: () => "/api/v1/common/users/me/client/summary/",
      transformResponse: (response) => transformResponse(response),
    }),
  }),
  overrideExisting: false,
});

// This logic needs to go on the backend
const useFetchCreditorPortalWorkflowStates = () => {
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { data: workflows } = homeApi.useFetchCreditorPortalWorkflowsQuery();
  const [
    fetchWorkflowStateExternalNames,
    { data: workflowsStateExternalNames, isSuccess: flowStatesSuccess },
  ] = homeApi.useLazyFetchCreditorPortalWorkflowStateExternalNamesQuery();

  // First, waiting for workflows to arrive
  useEffect(() => {
    if (workflows) {
      if (workflows.length === 0) {
        return setData([]);
      }
      const firstWorkflowId = workflows[0].id;
      // Fetch states for first workflow
      fetchWorkflowStateExternalNames({ workflowId: firstWorkflowId });
    }
  }, [fetchWorkflowStateExternalNames, workflows]);

  // Second, if workflow states arrive, return data
  useEffect(() => {
    if (workflowsStateExternalNames) {
      setData(workflowsStateExternalNames);
    }
  }, [workflowsStateExternalNames]);

  // Lastly, set isLoading variable
  useEffect(() => {
    if (flowStatesSuccess) {
      setIsLoading(false);
    }
  }, [flowStatesSuccess]);

  // simulates how RTK query hooks work
  return { data, isLoading };
};

export const {
  useFetchCreditorPortalWorkflowStateExternalNamesQuery,
  useFetchCreditorPortalWorkflowsQuery,
  useFetchClientSummaryQuery,
} = homeApi;

export { useFetchCreditorPortalWorkflowStates };

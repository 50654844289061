import { Form, Input, InputNumber } from "antd";
import { udfToComponentMap, snakeToCamelCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useUserType } from "features/auth";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useGetCreditorPortalCreditorQuery } from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useMemo } from "react";

function AccountConsumerStep({ creditorId }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { data: constants } = useFetchBackendConstantsQuery();

  const { data: agencySelectedCreditor } = useFetchCreditorQuery(
    { creditorId },
    { skip: !creditorId || isCreditorUserType },
  );
  const { data: creditorSelectedCreditor } = useGetCreditorPortalCreditorQuery(
    { creditorId },
    { skip: !creditorId || isAgencyUserType },
  );
  const selectedCreditor = agencySelectedCreditor || creditorSelectedCreditor;
  const creditorOptions = useMemo(() => {
    if (constants && selectedCreditor) {
      return constants?.paymentCategories?.invoiceable.filter((each) =>
        selectedCreditor.paymentConfig.paymentCategoryPriority.includes(each.value),
      );
    }
  }, [constants, selectedCreditor]);

  return (
    <>
      <Form.Item label="Client Reference ID" name="clientReferenceId">
        <Input />
      </Form.Item>
      <Form.Item
        label="Interest Rate"
        name="interestRate"
        tooltip="Interest is accumulated as annual APR, not compounded interest."
      >
        <AktPercentageInput />
      </Form.Item>
      <Form.Item
        label="Interest Calculation Date"
        name="interestStartDate"
        tooltip="Interest accumulates as annual APR, not compound interest, calculated off the principal starting from the interest calculation date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Date of Service"
        name="dateOfService"
        tooltip="The date the service was performed."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Itemization Date" name="itemizationDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Turnover Date" name="turnoverDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Date of First Delinquency" name="dateOfFirstDelinquency">
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Judgment Date" name="judgmentDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Last Statement Date" name="lastStatementDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Charge-off Date" name="chargeOffDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Total Previous Payment Amount"
        name="totalPreviousPaymentAmount"
        rules={[
          (formInstance) => ({
            // Required if lastPaymentDate is filled
            message: "This field is required.",
            validator(rule, value) {
              /* 
                Note: null >= 0 will return true so we need extra null checks
                If Last Payment Date is specified and:
                  - Payment Amount = null, then ERROR (“total previous payment amount must be specified if last payment date is specified”)
                  - Payment Amount = 0, then VALID and create a legacy payment
                  - Payment Amount > 0, then VALID and create a legacy payment
                If Last Payment Date is NOT specified and:
                  - Payment Amount = null, then VALID and do NOT create a legacy payment
                  - Payment Amount = 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
                  - Payment Amount > 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
              */
              const lastPaymentDate = formInstance.getFieldValue("lastPaymentDate");
              if (
                ((value === null || value < 0) && lastPaymentDate) ||
                (value !== null && value >= 0 && !lastPaymentDate)
              ) {
                return Promise.reject(new Error());
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber prefix="$" min={0} step={0.01} />
      </Form.Item>
      <Form.Item
        label="Last Payment Date"
        name="lastPaymentDate"
        rules={[
          (formInstance) => ({
            // Required if totalPreviousPaymentAmount is filled
            message: "This field is required.",
            validator(rule, value) {
              /* 
                Note: null >= 0 will return true so we need extra null checks
                If Last Payment Date is specified and:
                  - Payment Amount = null, then ERROR (“total previous payment amount must be specified if last payment date is specified”)
                  - Payment Amount = 0, then VALID and create a legacy payment
                  - Payment Amount > 0, then VALID and create a legacy payment
                If Last Payment Date is NOT specified and:
                  - Payment Amount = null, then VALID and do NOT create a legacy payment
                  - Payment Amount = 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
                  - Payment Amount > 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
              */
              const totalPreviousPaymentAmount = formInstance.getFieldValue(
                "totalPreviousPaymentAmount",
              );
              if (
                !value &&
                totalPreviousPaymentAmount !== null &&
                totalPreviousPaymentAmount >= 0
              ) {
                return Promise.reject(new Error());
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      {creditorOptions?.map((category) => (
        <Form.Item
          label={`Original ${category.display}`}
          name={category.value}
          key={category.value}
          rules={[
            {
              required: constants?.paymentCategories?.requiredCategories.includes(category.value),
            },
          ]}
        >
          <InputNumber prefix="$" min={0} step={0.01} />
        </Form.Item>
      ))}
      {selectedCreditor?.accountCustomFields?.map((field) => (
        <Form.Item
          label={field.name}
          name={["customFields", `${snakeToCamelCase(field.slug)}`]}
          key={field.id}
          rules={[{ required: field.isRequired }]}
        >
          {udfToComponentMap[field.type]}
        </Form.Item>
      ))}
    </>
  );
}

export default AccountConsumerStep;

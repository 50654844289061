import { Form, InputNumber, Select, Space } from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchDebtorTagsQuery } from "features/tags/tagsAPI";
import styled from "styled-components";

const StyledHeader = styled.h4`
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const StyledSpan = styled.span`
  margin-bottom: 24px;
  display: block;
  font-size: 13px;
`;

const FilterTitle = styled.div`
  padding: 0 0 8px;
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 4px;
`;

export function DebtorsFiltersStep() {
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const { data: debtorTags = [] } = useFetchDebtorTagsQuery();
  const { data: defaultWorkflowStates = [] } = useFetchAgencyWorkflowStates();
  const { data: productOfferings = [] } = useFetchProductOfferingsQuery();
  const { data: creditors = [] } = useFetchCreditorSummariesQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <>
      <StyledHeader>Filters</StyledHeader>
      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <Form.Item hidden name="type" />

      <Form.Item name={["filters", "states"]} label="Debtor State">
        <Select
          mode="multiple"
          filterOption={filterOption}
          options={(constants.states ?? []).map((state) => ({
            value: state.value,
            label: state.display,
          }))}
          placeholder="States..."
        />
      </Form.Item>

      <Form.Item name={["filters", "tagIds"]} label="Debtor Tags">
        <StyledSelect
          popupMatchSelectWidth={false}
          mode="multiple"
          placeholder="Debtor Tags..."
          filterOption={filterOption}
          options={debtorTags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
        />
      </Form.Item>

      <StyledSpan>Debtors matching these filter criteria will be selected.</StyledSpan>

      <Form.Item name={["filters", "assigneeIds"]} label="Collectors Assigned">
        <StyledSelect
          popupMatchSelectWidth={false}
          mode="multiple"
          placeholder="Collectors Assigned..."
          filterOption={filterOption}
          options={
            collectors && [
              {
                label: "Unassigned",
                value: null,
              },
              ...(collectors.map((c) => ({
                label: collectorFullName(c),
                value: c.id,
              })) ?? []),
            ]
          }
        />
      </Form.Item>

      <Form.Item name={["filters", "creditorIds"]} label="Clients">
        <StyledSelect
          maxTagTextLength={20}
          mode="multiple"
          placeholder="Select Clients..."
          filterOption={filterOption}
          options={(creditors ?? []).map((creditor) => ({
            value: creditor.id,
            label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
          }))}
        />
      </Form.Item>

      <Form.Item name={["filters", "statusCodes"]} label="Statuses">
        <StyledSelect
          popupMatchSelectWidth={false}
          mode="multiple"
          placeholder="Status..."
          filterOption={filterOption}
          options={defaultWorkflowStates.map((state) => ({
            label: [state.code, state.name].join(" - "),
            value: state.code,
            title: state.description,
          }))}
        />
      </Form.Item>

      <Form.Item name={["filters", "productOfferingIds"]} label="Product Offerings">
        <StyledSelect
          popupMatchSelectWidth={false}
          mode="multiple"
          placeholder="Product Offering..."
          filterOption={filterOption}
          options={productOfferings.map((productOffering) => ({
            label: productOffering.name,
            value: productOffering.id,
          }))}
        />
      </Form.Item>

      <Form.Item label="Turnover Date" name={["filters", "turnoverDate"]}>
        <AktDateRangePicker picker="date" allowClear />
      </Form.Item>

      <Form.Item label="Follow-up Date" name={["filters", "followUpDate"]}>
        <AktDateRangePicker picker="date" allowClear />
      </Form.Item>

      <Form.Item name={["filters", "lastCallDate"]} label="Last Call Date">
        <AktDateRangePicker picker="date" allowClear />
      </Form.Item>

      <Form.Item name={["filters", "lastPaymentDate"]} label="Last Paid Date">
        <AktDateRangePicker picker="date" allowClear />
      </Form.Item>

      <FilterTitle>Total Balance:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minAccountBalance"]}>
          <InputNumber placeholder="Min" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxAccountBalance"]}>
          <InputNumber placeholder="Max" controls={false} min="0" prefix="$" step="0.01" />
        </Form.Item>
      </Space>

      <FilterTitle>Score:</FilterTitle>
      <Space>
        <Form.Item name={["filters", "minExternalScore"]}>
          <InputNumber placeholder="Min" controls={false} min="0" step="0.01" />
        </Form.Item>
        <Form.Item name={["filters", "maxExternalScore"]}>
          <InputNumber placeholder="Max" controls={false} min="0" step="0.01" />
        </Form.Item>
      </Space>
    </>
  );
}

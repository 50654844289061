import { Modal, Table } from "antd";
import { camelToWords, formatCurrency, formatDate, snakeToCamelCase } from "common/utils";
import { useUserType } from "features/auth";
import { useFetchAccountBalanceHistoryQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchCreditorPortalAccountBalanceHistoryQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import styled from "styled-components";

const StyledPositiveSpan = styled.span`
  color: green;
`;

const StyledNegativeSpan = styled.span`
  color: red;
`;

function BalanceHistoryModal({ account, open, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const { data: agencyAccountBalanceHistoryData } = useFetchAccountBalanceHistoryQuery(
    { accountId: account?.id },
    {
      skip: !account?.id || !isAgencyUserType,
    },
  );
  const { data: creditorPortalAccountBalanceHistoryData } =
    useFetchCreditorPortalAccountBalanceHistoryQuery(
      { accountId: account?.id },
      {
        skip: !account?.id || isAgencyUserType,
      },
    );
  const data = isAgencyUserType
    ? agencyAccountBalanceHistoryData
    : creditorPortalAccountBalanceHistoryData;
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => formatDate(record.date),
    },
    {
      title: "Action Taken",
      dataIndex: "type",
      key: "type",
      render: (text, record) => camelToWords(snakeToCamelCase(record.type)),
    },
    {
      title: "Total Amount Changed",
      dataIndex: "total",
      render: (text, record) => {
        if (parseFloat(record.deltas.total) > 0) {
          return <StyledPositiveSpan>+{formatCurrency(record.deltas.total)}</StyledPositiveSpan>;
        }
        if (parseFloat(record.deltas.total) < 0) {
          return <StyledNegativeSpan>{formatCurrency(record.deltas.total)}</StyledNegativeSpan>;
        }
        return formatCurrency(record.deltas.total);
      },
    },
    {
      title: "Balance",
      children: [
        {
          title: "Principal",
          dataIndex: "principal",
          render: (text, record) => <strong>{formatCurrency(record.balances.principal)}</strong>,
        },
        {
          title: "Interest",
          dataIndex: "interest",
          render: (text, record) => <strong>{formatCurrency(record.balances.interest)}</strong>,
        },
        {
          title: "Fees",
          children: [
            {
              title: "Attorney Fees",
              dataIndex: "attorneyFees",
              render: (text, record) => formatCurrency(record.balances.attorneyFees),
            },
            {
              title: "Check Fees",
              dataIndex: "checkFees",
              render: (text, record) => formatCurrency(record.balances.checkFees),
            },
            {
              title: "Collection Fees",
              dataIndex: "collectionFees",
              render: (text, record) => formatCurrency(record.balances.collectionFees),
            },
            {
              title: "Court Fees",
              dataIndex: "courtFees",
              render: (text, record) => formatCurrency(record.balances.courtFees),
            },
            {
              title: "Handling Fees",
              dataIndex: "handlingFees",
              render: (text, record) => formatCurrency(record.balances.handlingFees),
            },
            {
              title: "Legal Fees",
              dataIndex: "legalFees",
              render: (text, record) => formatCurrency(record.balances.legalFees),
            },
            {
              title: "Lien Fees",
              dataIndex: "lienFees",
              render: (text, record) => formatCurrency(record.balances.lienFees),
            },
            {
              title: "Misc Fees",
              dataIndex: "miscFees",
              render: (text, record) => formatCurrency(record.balances.miscFees),
            },
            {
              title: "Service Fees",
              dataIndex: "serviceFees",
              render: (text, record) => formatCurrency(record.balances.serviceFees),
            },
            {
              title: "Total Fees",
              dataIndex: "totalFees",
              render: (text, record) => (
                <strong>{formatCurrency(record.balances.totalFees)}</strong>
              ),
            },
          ],
        },
        // {
        //   title: "Prior Interest",
        //   dataIndex: "priorInterest",
        //   render: (text, record) => formatCurrency(record.balances.priorInterest),
        // },
        // {
        //   title: "Treble Damages",
        //   dataIndex: "trebleDamages",
        //   render: (text, record) => formatCurrency(record.balances.trebleDamages),
        // },
        {
          title: "Total",
          dataIndex: "total",
          render: (text, record) => <strong>{formatCurrency(record.balances.total)}</strong>,
        },
      ],
    },
  ];

  return (
    <Modal
      width={900}
      maskClosable={false}
      title="Balance History"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <Table
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={data}
      />
    </Modal>
  );
}

export default BalanceHistoryModal;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table, Tag } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { camelToWords, capitalizeFirstLetter, snakeToCamelCase } from "common/utils";
import { PageHeader } from "components/pageHeader";
import {
  useDeleteCommunicationLimitMutation,
  useFetchCommunicationLimitsQuery,
} from "features/communicationLimitsTable/communicationLimitsApi";
import CommunicationLimitsModal from "features/communicationLimitsTable/components/communicationLimitsModal";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledTag = styled(Tag)`
  margin: 4px;
`;

export default function CommunicationLimitsTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const { data: communicationLimits, isLoading } = useFetchCommunicationLimitsQuery();
  const [deleteCommunicationLimit] = useDeleteCommunicationLimitMutation();
  const {
    data: {
      [PERMISSIONS.COMMUNICATION_RULE__CREATE]: isCommunicationRuleCreateAuthorized,
      [PERMISSIONS.COMMUNICATION_RULE__UPDATE]: isCommunicationRuleUpdateAuthorized,
      [PERMISSIONS.COMMUNICATION_RULE__DELETE]: isCommunicationRuleDeleteAuthorized,
    },
  } = useAuthorizations();

  const onUpdate = (record) => {
    setModalVisible(true);
    setSelectedRecord(record);
  };

  const onCancel = () => {
    setModalVisible(false);
    setSelectedRecord(null);
  };

  const onNewRule = () => {
    setModalVisible(true);
    setSelectedRecord(null);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Rule Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Geography",
      dataIndex: "states",
      key: "states",
      width: 150,
      ellipsis: true,
      render: (states, record) => {
        return record.isAllStates ? "All" : states.join(", ");
      },
    },
    {
      title: "# Contacts Allowed",
      dataIndex: "maxAllowed",
      key: "maxAllowed",
    },
    {
      title: "Contact Method(s)",
      dataIndex: "contactMethods",
      key: "contactMethods",
      render: (contactMethods) => {
        return contactMethods.map((method) => capitalizeFirstLetter(method)).join(", ");
      },
    },
    {
      title: "Rolling Time Period (days)",
      dataIndex: "rollingPeriod",
      key: "rollingPeriod",
    },
    {
      title: "Result Tags",
      render: (text, record) => {
        const resultTags = [
          ...(record?.callResults ?? []),
          ...(record?.mailResults ?? []),
          ...(record?.communicationResults ?? []),
        ];
        return (
          <>
            {resultTags.map((resultTag, index) => (
              <StyledTag key={index}>{camelToWords(snakeToCamelCase(resultTag))}</StyledTag>
            ))}
          </>
        );
      },
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {isCommunicationRuleUpdateAuthorized && (
            <EditOutlined key="edit" onClick={() => onUpdate(record)} />
          )}
          {isCommunicationRuleDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this communication limit rule?"
              onConfirm={() => deleteCommunicationLimit({ id: record.id })}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <StyledHeader>Communication Limits</StyledHeader>
      <StyledRow align="middle">
        <span>Define communication limits for debtors by geography.</span>
        {isCommunicationRuleCreateAuthorized && (
          <Button onClick={onNewRule} icon={<PlusOutlined />} type="link">
            Add Communication Limit
          </Button>
        )}
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        // @ts-ignore
        columns={columns}
        dataSource={communicationLimits}
      />
      {isModalVisible && (
        <CommunicationLimitsModal
          title={selectedRecord ? "Edit Communication Limit Rule" : "Add Communication Limit Rule"}
          record={selectedRecord}
          open={isModalVisible}
          onCancel={onCancel}
        />
      )}
    </>
  );
}

import api, { transformRequest, transformResponse } from "services/api";

export const debtorAccounts = api
  .enhanceEndpoints({
    addTagTypes: [
      "Accounts",
      "DebtorAccounts",
      "CreditorCost",
      "DebtorSystemLogs",
      "Documents",
      "LegalFees",
      "AccountBalanceHistory",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDebtorAccounts: build.query({
        query: (payload) => `/api/v1/core/debtors/${payload.debtorId}/accounts/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorAccounts", id })),
                { type: "DebtorAccounts", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorAccounts', id: 'LIST' }` is invalidated
              [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      fetchDebtorSystemLogs: build.query({
        query: (payload) => `/api/v1/common/debtors/${payload.debtorId}/system-logs/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? [{ type: "DebtorSystemLogs", id: "LIST" }]
            : [{ type: "DebtorSystemLogs", id: "LIST" }],
      }),
      fetchAccountBalanceHistory: build.query({
        query: (payload) => `/api/v1/core/accounts/${payload.accountId}/balance-history/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results, err, arg) => [{ type: "AccountBalanceHistory", id: arg.accountId }],
      }),
      createTask: build.mutation({
        query: (payload) => ({
          url: "/api/v1/core/communication/tasks/create/",
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "DebtorSystemLogs", id: "LIST" }],
      }),
      fetchSequenceTasks: build.query({
        query: (payload) => ({
          url: "/api/v1/core/communication/tasks/",
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      updateAccountPaymentData: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/update-payment-data/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAccounts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      createAccountDebtor: build.mutation({
        query: ({ accountId, ...payload }) => ({
          url: `/api/v1/core/accounts/${accountId}/debtors/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAccounts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      linkAccountDebtor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/debtors/link/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      unlinkAccountDebtor: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/debtors/unlink/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      updateFollowUpDate: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/update-follow-up-date/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAccounts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorAccounts", id: "LIST" }],
      }),
      fetchAccount: build.query({
        query: (payload) => `/api/v1/core/accounts/${payload.accountId}/`,
        transformResponse: (response) => transformResponse(response),
      }),
      deleteAccount: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/delete/`,
          method: "POST",
          body: transformRequest(payload.accountId),
        }),
        invalidatesTags: [
          { type: "DebtorAccounts", id: "LIST" },
          { type: "LegalFees", id: "LIST" },
        ],
      }),
      updateAccount: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/${payload.accountId}/update/`,
          method: "POST",
          body: transformRequest(payload),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: (result, error, arg) => [
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "AccountBalanceHistory", id: arg.id },
        ],
      }),
      updateAccountStatus: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/workflow-tasks/${payload.workflowTaskId}/update/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAccounts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      postAssignAccountsToForwardingEntity: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/accounts/update-forwarding-entity/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorAccounts-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      createCreditorCost: build.mutation({
        query: (payload) => ({
          url: `/api/v1/core/creditor-costs/create/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CreditorCost-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [
          { type: "CreditorCost", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
        ],
      }),
      createDispute: build.mutation({
        query: ({ file, ...values }) => ({
          url: `/api/v1/core/disputes/create/`,
          method: "POST",
          body: transformRequest(values),
        }),
        invalidatesTags: [
          { type: "Accounts", id: "LIST" },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "Documents", id: "LIST" },
        ],
      }),
      updateDispute: build.mutation({
        query: ({ disputeId, file, ...values }) => ({
          url: `/api/v1/core/disputes/${disputeId}/update/`,
          method: "POST",
          body: transformRequest(values),
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Accounts", id: arg.id },
          { type: "DebtorAccounts", id: "LIST" },
          { type: "DebtorSystemLogs", id: "LIST" },
          { type: "Documents", id: arg.id },
        ],
      }),
      fetchDisputeCategories: build.query({
        query: (payload) => ({
          url: `/api/v1/core/dispute-category-tags/`,
          method: "POST",
          body: transformRequest({ creditorId: payload?.creditorId }),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      fetchCreditReportHistory: build.query({
        query: (payload) => ({
          url: `/api/v1/common/accounts/${payload.accountId}/credit-report-history/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),

    overrideExisting: false,
  });

export const {
  useFetchAccountBalanceHistoryQuery,
  useCreateTaskMutation,
  useFetchAccountQuery,
  useFetchDebtorAccountsQuery,
  useFetchSequenceTasksQuery,
  useUpdateAccountPaymentDataMutation,
  useUpdateFollowUpDateMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  usePostAssignAccountsToForwardingEntityMutation,
  useCreateCreditorCostMutation,
  useUpdateAccountStatusMutation,
  useFetchDebtorSystemLogsQuery,
  useCreateAccountDebtorMutation,
  useLinkAccountDebtorMutation,
  useUnlinkAccountDebtorMutation,
  useCreateDisputeMutation,
  useUpdateDisputeMutation,
  useFetchDisputeCategoriesQuery,
  useFetchCreditReportHistoryQuery,
} = debtorAccounts;

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table, Tooltip, message } from "antd";
import AddBankruptcyModal from "features/bankruptcies/addBankruptcyModal";
import {
  useDeleteDebtorBankruptcyMutation,
  useFetchDebtorBankruptciesQuery,
} from "features/bankruptcies/bankruptciesAPI";

import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";
import { formatDate } from "common/utils";

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

const StyledTable = styled(Table)`
  & .ant-table-cell:not(th) {
    padding: 0px 8px !important;
  }
`;

function BankruptciesTable({ debtorId }) {
  const [isBankruptcyModalVisible, setBankruptcyModalVisible] = useState(false);
  const [deleteBankruptcy] = useDeleteDebtorBankruptcyMutation();
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: bankruptcies, isLoading } = useFetchDebtorBankruptciesQuery({ debtorId });

  const {
    data: {
      [PERMISSIONS.DEBTOR_BANKRUPTCY__GET]: isBankruptcyGetAuthorized,
      [PERMISSIONS.DEBTOR_BANKRUPTCY__CREATE]: isBankruptcyCreateAuthorized,
      [PERMISSIONS.DEBTOR_BANKRUPTCY__UPDATE]: isBankruptcyUpdateAuthorized,
      [PERMISSIONS.DEBTOR_BANKRUPTCY__DELETE]: isBankruptcyDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (payload) => {
    const result = await deleteBankruptcy(payload);
    if ("data" in result) {
      message.success("Bankruptcy deleted successfully!");
    }
  };

  const onBankruptcyOk = () => {
    setActiveRecord(null);
    setBankruptcyModalVisible(false);
  };

  const onBankruptcyCancel = () => {
    setActiveRecord(null);
    setBankruptcyModalVisible(false);
  };

  const onUpdateBankruptcy = (record) => {
    setBankruptcyModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "Case Number",
      dataIndex: "caseNumber",
      key: "caseNumber",
      render: (text, record) => record?.caseNumber ?? "-",
    },
    {
      title: "Filing Date",
      dataIndex: "filingDate",
      key: "filingDate",
      render: (text, record) =>
        record?.filingDate ? formatDate(record.filingDate) : "-",
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      key: "filingStatus",
      render: (text, record) => record?.filingStatus ?? "-",
    },
    {
      title: "Chapter",
      dataIndex: "chapter",
      key: "chapter",
      render: (text, record) => record?.chapter ?? "-",
    },
    {
      title: "Dismissal Date",
      dataIndex: "dismissalDate",
      key: "dismissalDate",
      render: (text, record) =>
        record?.dismissalDate ? formatDate(record?.dismissalDate) : "-",
    },
    {
      title: "Discharge Date",
      dataIndex: "dischargeDate",
      key: "dischargeDate",
      render: (text, record) =>
        record?.dischargeDate ? formatDate(record?.dischargeDate) : "-",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hidden: !isBankruptcyDeleteAuthorized && !isBankruptcyUpdateAuthorized,
      render: (text, record) => {
        return (
          <Space size="middle">
            {isBankruptcyUpdateAuthorized && (
              <Tooltip placement="bottom" title="Edit" key="edit">
                <EditOutlined key="edit" onClick={() => onUpdateBankruptcy(record)} />
              </Tooltip>
            )}
            {isBankruptcyDeleteAuthorized && (
              <Popconfirm
                placement="topLeft"
                okText="Yes"
                title="Are you sure you want to delete this record?"
                onConfirm={() => handleDelete({ debtorId, bankruptcyId: record.id })}
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return isBankruptcyGetAuthorized ? (
    <>
      {isBankruptcyCreateAuthorized && (
        <StyledButton
          type="link"
          onClick={() => setBankruptcyModalVisible(true)}
          icon={<PlusOutlined />}
        >
          Add Bankruptcy
        </StyledButton>
      )}
      <StyledTable
        bordered
        loading={isLoading}
        style={{ cursor: "pointer" }}
        // @ts-ignore
        columns={columns}
        dataSource={bankruptcies}
        scroll={{ x: "max-content" }}
      />
      {isBankruptcyModalVisible && (
        <AddBankruptcyModal
          bankruptcyRecord={activeRecord}
          debtorId={debtorId}
          title="Add Bankruptcy"
          open={isBankruptcyModalVisible}
          onOk={onBankruptcyOk}
          onCancel={onBankruptcyCancel}
        />
      )}
    </>
  ) : null;
}

export default BankruptciesTable;

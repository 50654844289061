import { Form, Modal, Select, Skeleton } from "antd";
import { useFetchAuthenticationMethodsQuery } from "features/authenticationMethods/authenticationMethodsAPI";
import { useFetchSkipTraceWorkflowsQuery } from "features/bulkActions/bulkActionsAPI";

export default function SkipTracingOptionsModal({ open, onSubmit, onCancel, title, provider }) {
  const {
    data: skipTraceWorkflows = [],
    isSuccess: isSkipTraceSuccess,
    isLoading: isSkipTraceLoading,
  } = useFetchSkipTraceWorkflowsQuery({
    provider,
  });
  const {
    data: authenticationMethods = [],
    isLoading: isAuthMethodsLoading,
    isSuccess: isAuthMethodSuccess,
  } = useFetchAuthenticationMethodsQuery();

  const [form] = Form.useForm();

  const initialValues = {
    skipTraceSettingId: skipTraceWorkflows.length === 1 ? skipTraceWorkflows[0].id : undefined,
    authenticationMethodId:
      authenticationMethods.length === 1 ? authenticationMethods[0].id : undefined,
  };

  const onOk = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onOk}
      width={900}
      title={title}
    >
      {!isSkipTraceSuccess || !isAuthMethodSuccess ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          validateMessages={{ required: "This is a required field" }}
        >
          <Form.Item
            name="skipTraceSettingId"
            label="Skip Trace Workflow"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select one..."
              allowClear
              options={skipTraceWorkflows.map(({ id, workflowName }) => ({
                label: `ID: ${id} (Name: ${workflowName})`,
                value: id,
              }))}
              loading={isSkipTraceLoading}
            />
          </Form.Item>
          <Form.Item
            name="authenticationMethodId"
            label="Authentication Method"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select one..."
              allowClear
              options={authenticationMethods.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              loading={isAuthMethodsLoading}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

import { Table, Tag } from "antd";
import { idToObjectMap, populateKey } from "common/utils";
import { useMemo } from "react";

export function FeePlanRulesTable(props) {
  const { feePlans, rules, productOfferings } = props;

  const productOfferingsById = useMemo(() => {
    return idToObjectMap(productOfferings);
  }, [productOfferings]);

  const columns = [
    {
      title: "Fee Plan Name",
      dataIndex: "feePlan",
      key: "feePlan",
      render: (index, rule) =>
        feePlans?.find((feePlan) => feePlan.id === rule.feePlanId)?.name ??
        `Fee Plan #${rule.feePlanId}`,
    },
    {
      title: "Legal Status",
      dataIndex: "isLegal",
      key: "isLegal",
      render: (index, rule) =>
        ({
          true: <Tag color="red">Contains Legal</Tag>,
          false: <Tag color="green">No Legal</Tag>,
          null: null,
        }[rule.isLegal]),
    },
    {
      title: "Product",
      dataIndex: "productOfferingId",
      key: "productOfferingId",
      render: (index, rule) => productOfferingsById[rule.productOfferingId]?.name,
    },
    {
      title: "Account Balance",
      children: [
        {
          title: "From",
          dataIndex: "minAccountBalance",
          key: "minAccountBalance",
        },
        {
          title: "To",
          dataIndex: "maxAccountBalance",
          key: "maxAccountBalance",
        },
      ],
      key: "dollarAmount",
    },
    {
      title: "Days Since Upload",
      children: [
        {
          title: "From",
          dataIndex: "minAccountAgeDays",
          key: "minAccountAgeDays",
        },
        {
          title: "To",
          dataIndex: "maxAccountAgeDays",
          key: "maxAccountAgeDays",
        },
      ],
      key: "daysSinceUpload",
    },
  ];
  return <Table bordered columns={columns} dataSource={populateKey(rules)} pagination={false} />;
}

import { EditOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Card, Col, Row, Space, Table, message } from "antd";
import { booleanToYesNo, capitalizeFirstLetter, renderAddress } from "common/utils";
import { FormWizard } from "components/formWizard";
import {
  useCreateBureauSettingMutation,
  useFetchAgencyQuery,
  useFetchBureauSettingsQuery,
  useUpdateAgencyMutation,
  useUpdateBureauSettingMutation,
} from "features/creditReporting/creditReportingAPI";
import CompanyInfoStep from "features/creditReporting/wizardSteps/companyInfoStep";
import CreditBureauInfoStep from "features/creditReporting/wizardSteps/creditBureauInfoStep";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-top: 16px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

const StyledTable = styled(Table)``;

const CREDIT_REPORTING_AGENCY = {
  experian: "Experian",
  equifax: "Equifax",
  transunion: "TransUnion",
  innovis: "Innovis",
  microbilt: "Microbilt",
};

export default function CreditReporting() {
  const [isReportingWizardView, setIsReportingWizardView] = useState(false);
  const { data: agency } = useFetchAgencyQuery();
  const { data: creditReportingProviders, isLoading } = useFetchBureauSettingsQuery();
  const [modifyBureauSettings] = useUpdateBureauSettingMutation();
  const [createBureauSettings] = useCreateBureauSettingMutation();
  const [updateAgency] = useUpdateAgencyMutation();
  const { data: constants } = useFetchBackendConstantsQuery();

  const processCreditReportingProvider = async (payload) => {
    const result = payload.id
      ? await modifyBureauSettings(payload)
      : await createBureauSettings(payload);

    if ("error" in result) {
      message.error("Could not handle the request");
      throw new Error("Could not handle the request");
    }
  };

  const processAgencyUpdate = async (payload) => {
    const result = updateAgency(payload);
    if ("error" in result) {
      message.error("Could not handle the request");
      throw new Error("Could not handle the request");
    }
  };

  const reportingSteps = [
    {
      title: "Company Information",
      form: <CompanyInfoStep />,
      onNext: async (data) => processAgencyUpdate(data),
    },
    ...Object.entries(CREDIT_REPORTING_AGENCY).map(([type, title]) => {
      return {
        title,
        form: <CreditBureauInfoStep title={title} type={type} />,
        // @ts-ignore
        onNext: async ({ [type]: data }) => processCreditReportingProvider({ ...data, type }),
      };
    }),
  ];

  const columns = [
    {
      title: "Company ID",
      dataIndex: "agency",
      key: "agencyId",
      render: (text, record) => record?.id,
    },
    {
      title: "Company Name",
      dataIndex: "agency",
      key: "agencyName",
      render: (text, record) => record?.name,
    },
    {
      title: "Company Address",
      render: (text, record) => renderAddress(record?.address, constants?.states),
      key: "address",
    },
    {
      title: "EIN Tax ID",
      dataIndex: "agency",
      key: "einTaxId",
      render: (text, record) => record?.einTaxId,
    },
    {
      title: "Collection Manager",
      dataIndex: "agency",
      key: "collectionManagerName",
      render: (text, record) => record?.collectionManagerName,
    },
    {
      title: "Collection Manager's Phone",
      dataIndex: "agency",
      key: "collectionManagerPhone",
      render: (text, record) => record?.collectionManagerPhone,
    },
    {
      title: "Reporting To",
      children: creditReportingProviders?.map((provider) => ({
        title: capitalizeFirstLetter(provider?.type),
        dataIndex: provider?.type,
        key: provider?.type,
        render: () => booleanToYesNo(provider?.isEnabled),
      })),
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      dataIndex: "action",
      key: "action",
      render: () => (
        <Space size="middle">
          <AntDToolTip placement="bottom" title="Modify Credit Report Settings">
            <EditOutlined key="dispute" onClick={() => setIsReportingWizardView(true)} />
          </AntDToolTip>
        </Space>
      ),
    },
  ];

  const onDoneReportingWizard = async () => {
    message.success("Processing complete!");
    setIsReportingWizardView(false);
  };

  const onCancelReportingWizard = () => {
    setIsReportingWizardView(false);
  };

  const byProviderType = creditReportingProviders?.reduce(
    (acc, provider) => ({ ...acc, [provider.type]: provider }),
    {},
  );

  const initialValues = {
    ...agency,
    ...byProviderType,
  };

  return (
    <>
      <h4>Credit Reporting</h4>
      <StyledRow align="middle">
        {!isReportingWizardView ? (
          <Col>
            <StyledTable
              loading={isLoading}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
              // @ts-ignore
              columns={columns}
              dataSource={[agency]}
            />
          </Col>
        ) : null}
      </StyledRow>
      {isReportingWizardView ? (
        <StyledCard>
          <h3>Credit Reporting</h3>
          <FormWizard
            steps={reportingSteps}
            onCancel={onCancelReportingWizard}
            onDone={onDoneReportingWizard}
            initialValues={initialValues}
          />
        </StyledCard>
      ) : null}
    </>
  );
}

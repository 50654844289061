import { useFetchAgencyQuery } from "features/creditReporting/creditReportingAPI";
import moment from "moment-timezone";
import { GlobalErrorPage } from "pages/errorPages";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const MomentContext = createContext({});

function MomentProvider({ children }) {
  // const { data: me, isLoading, isFetching } = useFetchMeQuery();
  const { data: agency, isError } = useFetchAgencyQuery();
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  const value = useMemo(() => ({}), []);

  useEffect(() => {
    if (agency) {
      // Sets default timezone for all moment objects
      moment.tz.setDefault(agency?.timezone);
      setIsLoadingDone(true);
    }
  }, [agency]);

  if (!isLoadingDone) {
    return null;
  }

  if (isError) {
    return <GlobalErrorPage />;
  }

  return <MomentContext.Provider value={value}>{children}</MomentContext.Provider>;
}

export default MomentProvider;

export function useTimezone() {
  const context = useContext(MomentContext);
  if (context === undefined) {
    throw new Error(`useTimezone must be used within a MomentContext.Provider`);
  }
  return context;
}

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Row, Select, Space, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, formatDate, snakeToCamelCase } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { DisplayComponent } from "components/formItems";
import DisplayEmail from "components/formItems/displayEmail";
import { useUserType } from "features/auth";
import {
  useCreateDebtorEmailMutation,
  useDeleteDebtorEmailMutation,
  useUpdateDebtorEmailMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Assign to undefined to make it think it's optional
export function DebtorEmailInfoCard({
  email = undefined,
  isNew = undefined,
  onCreate = undefined,
  onCancel = undefined,
  title = undefined,
}) {
  const { isAgencyUserType } = useUserType();
  const [form] = Form.useForm();
  const { debtorId } = useParams();
  const [isEditing, setIsEditing] = useState(isNew || false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createDebtorEmail, { isLoading: isCreateLoading }] = useCreateDebtorEmailMutation();
  const [updateDebtorEmail, { isLoading: isUpdateLoading }] = useUpdateDebtorEmailMutation();
  const [deleteDebtorEmail, { isLoading: isDeleteLoading }] = useDeleteDebtorEmailMutation();

  // Auto sets consent status change date when status value changes to "consent"
  const onConsentStatusChange = useCallback(
    (value) => {
      if (
        value === "consent" &&
        // The intention here isis: if the user touched the value manually
        // (excluding when using the clearing buttn)
        // we don't want to mess with user selected values.
        (!form.isFieldTouched("consentStatusChangeDate") ||
          !form.getFieldValue("consentStatusChangeDate"))
      ) {
        form.setFields([
          // setFieldValue would set touched to true, use this to avoid that.
          {
            name: "consentStatusChangeDate",
            value: formatDate(moment().endOf("day")),
            touched: false,
          },
        ]);
      }
    },
    [form],
  );

  const {
    data: {
      [PERMISSIONS.DEBTOR_EMAIL__CREATE]: isAddDebtorEmailAuthorized,
      [PERMISSIONS.DEBTOR_EMAIL__UPDATE]: isUpdateDebtorEmailAuthorized,
      [PERMISSIONS.DEBTOR_EMAIL__DELETE]: isDeleteDebtorEmailAuthorized,
    },
  } = useAuthorizations();

  const editableDataSourceTypeValues = constants?.editableDataSourceTypes?.map(
    ({ value }) => value,
  );

  const deleteEmail = async () => {
    const result = await deleteDebtorEmail({ debtorId, emailId: email.id });
    if ("data" in result) {
      message.success("Email deleted successfully!");
    }
  };

  const toggleIsEditing = async () => {
    if (isEditing) {
      let values;
      try {
        values = await form.validateFields();
      } catch (e) {
        return null; // Form will handle error messages
      }

      const action = isNew
        ? createDebtorEmail({
            debtorId,
            ...values,
          })
        : updateDebtorEmail({
            debtorId,
            emailId: email.id,
            ...values,
          });
      const result = await action;

      if ("data" in result) {
        message.success("Saved successfully!");
        if (isNew) onCreate();
      }

      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error(`Could not ${isNew ? "create" : "update"} email`);
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      form.setFieldsValue(email);
    }
  };

  const onPrimaryCancel = () => {
    setIsEditing(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 12 },
    },
  };

  return (
    <StyledCard>
      <StyledHeader>
        <h4>{isNew ? "New Email" : title}</h4>
        {!isEditing &&
          isAgencyUserType &&
          (isUpdateDebtorEmailAuthorized || isDeleteDebtorEmailAuthorized) && (
            <Row>
              {isUpdateDebtorEmailAuthorized && (
                <Button type="link" icon={<EditOutlined />} onClick={toggleIsEditing} />
              )}
              {isDeleteDebtorEmailAuthorized && (
                <Popconfirm
                  placement="left"
                  okText="Yes"
                  title="Are you sure you want to delete this email?"
                  onConfirm={deleteEmail}
                >
                  <Button loading={isDeleteLoading} type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Row>
          )}
      </StyledHeader>
      <Form
        labelAlign="left"
        form={form}
        {...formItemLayout}
        initialValues={{ dataSource: "", status: "", consentStatus: "unknown" }}
      >
        <DisplayEmail
          isEditing={isEditing}
          slice={email}
          label="Email Address"
          name="address"
          rules={[{ required: true, message: "Address is required" }]}
        />
        <DisplayComponent
          isEditing={isEditing}
          slice={email}
          label="Type"
          name="type"
          outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
          rules={[{ required: true, message: "Type is required" }]}
        >
          <Select
            options={constants?.emailTypes.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={email}
          label="Status"
          name="status"
          outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
        >
          <Select
            options={constants?.emailAddressStatuses.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={email}
          label="Consent Status"
          name="consentStatus"
          outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
          rules={[{ required: true, message: "Consent Status is required" }]}
        >
          <Select
            onChange={onConsentStatusChange}
            options={constants?.contactPermissionStatuses.map(({ display, value }) => ({
              value,
              label: display,
            }))}
          />
        </DisplayComponent>
        <DisplayComponent
          isEditing={isEditing}
          slice={email}
          label="Consent date"
          name="consentStatusChangeDate"
          outputFormatter={formatDate}
        >
          <AktDatePicker />
        </DisplayComponent>
        {isEditing && (
          <>
            <DisplayComponent isEditing={isEditing} slice={email} label="Notes" name="notes">
              <Input.TextArea placeholder="Add Notes" />
            </DisplayComponent>
            {/* Only allow setting of data source if no data source is set, or it is editable (i.e. present in the editableDataSourceTypes) */}
            {(!email?.dataSource || editableDataSourceTypeValues.includes(email?.dataSource)) && (
              <DisplayComponent
                isEditing={isEditing}
                slice={email}
                label="Data Source"
                name="dataSource"
                outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
              >
                <Select
                  options={constants?.editableDataSourceTypes?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
                />
              </DisplayComponent>
            )}
            <StyledButtonContainer>
              <Space>
                <Button onClick={isNew ? onCancel : onPrimaryCancel}>Cancel</Button>
                {(isAddDebtorEmailAuthorized || isUpdateDebtorEmailAuthorized) && (
                  <Button
                    loading={isCreateLoading || isUpdateLoading}
                    onClick={toggleIsEditing}
                    type="primary"
                  >
                    Save
                  </Button>
                )}
              </Space>
            </StyledButtonContainer>
          </>
        )}
        {!isEditing && (
          <>
            <DisplayComponent
              isEditing={isEditing}
              slice={email}
              label="Data Source"
              name="dataSource"
              outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={email}
              label="Created At"
              name="createdAt"
              outputFormatter={(value) => moment(value).format(DATE_TIME_FORMAT)}
            />
          </>
        )}
      </Form>
    </StyledCard>
  );
}

import { Form, Modal, Select, Tag } from "antd";
import { TableFormList } from "components/formList";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 400px;
  padding-top: 8px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function PhoneOptionsModal({
  open,
  onSubmit,
  onCancel,
  title,
  provider,
  operationType,
}) {
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const [dataSource, setDataSource] = useState([]);
  const { data: communicationMethods = [] } = useFetchCommunicationMethodsQuery(
    {
      provider,
    },
    {
      skip: !provider,
    },
  );
  const [form] = Form.useForm();

  const onDelete = (record) => {
    setDataSource((prev) => {
      return prev.filter((d) => {
        return d.key !== record.key;
      });
    });
  };

  const onSave = ({ index, ...record }) => {
    if (index === -1) {
      setDataSource((prev) => [...prev, { ...record, key: Math.random() }]);
    } else {
      setDataSource((prev) => {
        const copy = [...prev];
        copy[index] = record;
        return copy;
      });
    }
    return { data: true };
  };

  const columns = [
    {
      dataIndex: "type",
      editable: true,
      inputType: "select",
      title: "Phone Type",
      options: (constants.phoneTypes ?? []).map(({ display, value }) => ({
        value,
        label: display,
      })),
      rules: [
        {
          required: true,
          message: "Select a phone type",
        },
      ],
      render: (text, record) => {
        return (constants.phoneTypes ?? []).find((type) => type.value === record.type)?.display;
      },
    },
    {
      dataIndex: "includeStatuses",
      editable: true,
      inputType: "select",
      options: (constants.phoneStatuses ?? []).map((status) => ({
        label: status.display,
        value: status.value,
      })),
      title: "Include Phone Statuses",
      inputProps: {
        allowClear: true,
        mode: "multiple",
      },
      render: (text, record) => {
        return (constants.phoneStatuses ?? [])
          .filter((status) => (record.includeStatuses ?? []).includes(status.value))
          .map((status) => <Tag key={status.value}>{status.display}</Tag>);
      },
    },
    {
      dataIndex: "excludeStatuses",
      editable: true,
      inputType: "select",
      options: (constants.phoneStatuses ?? []).map((status) => ({
        label: status.display,
        value: status.value,
      })),
      title: "Exclude Phone Statuses",
      inputProps: {
        allowClear: true,
        mode: "multiple",
      },
      render: (text, record) => {
        return (constants.phoneStatuses ?? [])
          .filter((status) => (record.excludeStatuses ?? []).includes(status.value))
          .map((status) => <Tag key={status.value}>{status.display}</Tag>);
      },
    },
  ];

  const communicationMethodOptions = communicationMethods
    .filter((communicationMethod) => {
      return communicationMethod.provider === provider;
    })
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const onOk = async () => {
    const values = await form.validateFields();
    onSubmit({ ...values, phoneConfigs: dataSource });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onOk}
      width={900}
      title={title}
    >
      {operationType === "sendText" && (
        <StyledForm labelAlign="left" layout="vertical" form={form}>
          <Form.Item
            label="Communication Method"
            name="template"
            rules={[
              {
                required: true,
                message: "Please select a communication method",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              popupMatchSelectWidth={false}
              options={communicationMethodOptions}
              placeholder="Select one..."
              allowClear
            />
          </Form.Item>
        </StyledForm>
      )}
      <h5>Phone Configuration</h5>
      <TableFormList data={dataSource} columns={columns} onSave={onSave} onDelete={onDelete} />
    </Modal>
  );
}

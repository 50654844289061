/* eslint-disable camelcase */
// Draws a card that displays the basic information and a list of arguments
// description: string
// display: string
// func: string, e.g. "account.status_in"

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Popconfirm, Select, Space, Table, Tooltip } from "antd";
import { capitalizeFirstLetter, snakeToCamelCase } from "common/utils";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { getCollectionOptions } from "features/workflows/util";
import { useFetchSchemaQuery } from "features/workflows/workflowAPI";
import { useCallback, useEffect } from "react";
import styled from "styled-components";
import ArgDisplay from "./argDisplay";
import ArgInput from "./argInput";

const StyledTable = styled(Table)`
  margin-top: 1em;
  margin-bottom: 1em;
  &&& .ant-table-cell {
    padding: 8px 8px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

const StyledSelect = styled(Select)`
  width: 300px;
  min-width: 300px;
  max-width: 300px;
`;

const StyledDiv = styled.div`
  line-height: 2;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

// items - array of [{ key, description, display, func, args }]
// args - array of [{ type, subtype, name, is_required, display, choices }]
// cardType - "condition" or "action"
// items - list of items to display
// setItems - setter for items
// editKey - key of item being edited, either null or a number
// setEditKey - setter for editKey
export default function EditList({ trigger, cardType, items, setItems, editKey, setEditKey }) {
  // load schema with definitions
  const { data: schema, isLoading: isSchemaLoading } = useFetchSchemaQuery();
  const { data: constants, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();

  const isLoading = isSchemaLoading || isConstantsLoading;

  const [form] = Form.useForm();
  // get collection based on cardType
  const collection = cardType === "condition" ? schema?.conditions : schema?.actions;
  // get options for a first func select in the row
  const funcOptions = trigger
    ? getCollectionOptions(trigger, collection, constants?.workflowTriggerToAllowedModels)
    : [];

  // shortcut to get row by func name
  const getRowByFunc = useCallback(
    (func) => collection?.find((obj) => obj.func === func),
    [collection],
  );
  const handleDelete = (key) => {
    setItems((prev) => prev.filter((item) => item.key !== key));
  };
  const handleEdit = (key) => {
    setEditKey(key);
    form.setFieldsValue(items.find((item) => item.key === key));
  };
  const handleSave = async () => {
    const values = await form.validateFields();
    const args = values?.args ?? {};

    /*
    The values here can come in 1 of 2 forms due to the DebounceSelect search feature.

    Example 1:
      [{
        "label": <name of model (String)>
        "value": <id of model (Integer)>
      }]
    
    Example 2:
      [<id of model (Integer)>]
    
    Example 1 must be changed into Example 2 for ArgDisplay and ArgInput to display the
    correct label.
    */
    Object.keys(args).forEach((key) => {
      if (Array.isArray(values.args[key])) {
        values.args[key] = values.args[key].map((item) => {
          if (typeof item === "object" && "value" in item) {
            return item.value;
          }

          return item;
        });
      }

      if (typeof values.args[key] === "object" && "value" in values.args[key]) {
        values.args[key] = values.args[key].value;
      }
    });

    setItems((prev) => {
      const index = prev.findIndex((item) => item.key === editKey);
      // drop isNew flag
      return [
        ...prev.slice(0, index),
        { ...values, args: values.args ?? {}, key: editKey },
        ...prev.slice(index + 1),
      ];
    });
    setEditKey(null);
  };
  const handleCancel = useCallback(() => {
    setItems((prev) => {
      const currentItem = prev.find((item) => item.key === editKey);
      const index = prev.findIndex((item) => item.key === editKey);

      // if item is new, remove it from the list
      if (currentItem?.isNew) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
    setEditKey(null);
  }, [editKey, setEditKey, setItems]);
  const handleAdd = () => {
    const key = Math.random();
    // key - unique random string to identify the item
    // isNew - flag to indicate that the item is new
    setItems([...items, { key, isNew: true }]);
    setEditKey(key);
  };

  // reset form when no item is being edited
  useEffect(() => {
    if (!editKey) {
      form.resetFields();
    }
  }, [editKey, form]);

  const columns = [
    {
      title: capitalizeFirstLetter(cardType),
      render: (text, record) => {
        const { key, func } = record;
        return editKey === key ? (
          <StyledFormItem
            name="func"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <StyledSelect
              showSearch
              filterOption={filterOption}
              options={funcOptions}
              disabled={isLoading}
              placeholder={`Select ${cardType}...`}
              onChange={() => form.setFieldValue(["args"], undefined)}
            />
          </StyledFormItem>
        ) : (
          getRowByFunc(func)?.display
        );
      },
      key: "func",
      width: 307,
    },
    {
      title: "Parameters",
      render: (text, record) => {
        const { key, func, args } = record;
        return editKey === key ? (
          <StyledFormItem
            noStyle
            shouldUpdate={(prev, curr) =>
              // @ts-ignore
              prev.func !== curr.func
            }
          >
            {({ getFieldValue }) => {
              const selectedFunc = getFieldValue("func");
              const selectedArgs = getRowByFunc(selectedFunc)?.args ?? [];
              return selectedArgs.map(({ type, subtype, name, isRequired, display, choices }) => {
                // We are merging schema and data here. Meanwhile data part is camelCased, we need to ensure schema is camelCased as well
                const camelCasedName = snakeToCamelCase(name);
                return (
                  <ArgInput
                    key={name}
                    type={type}
                    subtype={subtype}
                    name={["args", camelCasedName]}
                    isRequired={isRequired}
                    display={display}
                    choices={choices}
                  />
                );
              });
            }}
          </StyledFormItem>
        ) : (
          getRowByFunc(func)?.args?.map(({ type, subtype, choices, name, display }) => {
            const camelCasedName = snakeToCamelCase(name);
            const field = args?.[camelCasedName];
            const renderedDisplayValue =
              typeof field === "object" && "label" in field ? field.label : field;
            return (
              <StyledDiv key={name}>
                {display}:{" "}
                <ArgDisplay
                  type={type}
                  subtype={subtype}
                  choices={choices}
                  displayValue={renderedDisplayValue}
                />
              </StyledDiv>
            );
          })
        );
      },
      key: "parameters",
    },
    {
      title: "Actions",
      render: (text, record) => {
        const { key } = record;
        return editKey === key ? (
          <Space>
            <Button type="default" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </Space>
        ) : (
          <>
            {" "}
            <Tooltip placement="bottom" title="Edit" key="edit">
              <EditOutlined
                key="edit"
                onClick={() => {
                  if (editKey === null) {
                    handleEdit(key);
                  }
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this?"
              onConfirm={() => handleDelete(key)}
              disabled={editKey !== null}
            >
              <DeleteOutlined key="delete-line" />
            </Popconfirm>
          </>
        );
      },
      key: "actions",
      width: 180,
    },
  ];

  return (
    <>
      <Button
        onClick={() => handleAdd()}
        icon={<PlusOutlined />}
        type="default"
        disabled={editKey !== null || !trigger}
      >
        Add {capitalizeFirstLetter(cardType)}
      </Button>
      <Form form={form} component={false}>
        <StyledTable
          // @ts-ignore
          dataSource={items}
          columns={columns}
          pagination={false}
        />
      </Form>
    </>
  );
}

// @ts-nocheck
import { Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { capitalizeFirstLetter } from "common/utils";
import { getDefaultColumns } from "features/accountsTable/agencyPortal/accountsTable";
import { SORT_ORDER } from "features/appearance/constants";
import { preTransformDateFilters } from "features/bulkActions/components/accountBulkActionsModal";
import { AccountFiltersStep } from "features/bulkActions/wizardSteps/accountFiltersStep";
import {
  useFetchRolesQuery,
  useFetchUsersQuery,
} from "features/userManagementTable/userManagementAPI";
import {
  useCreateWorklistMutation,
  useUpdateWorklistMutation,
} from "features/workList/workListAPI";
import { useMemo } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 800px;
  width: 100%;
`;

function AddWorklistModal({ title, onCancel, onOk, worklist = {} }) {
  const [form] = useForm();
  const [createWorklist, { isLoading: isCreateLoading }] = useCreateWorklistMutation();
  const [updateWorklist, { isLoading: isUpdateLoading }] = useUpdateWorklistMutation();
  const { data: roles, isLoading: isRolesLoading } = useFetchRolesQuery();
  const { data: users, isLoading: isUsersLoading } = useFetchUsersQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const defaultSort = values.sortOrder
      ? `${values.sortOrder === SORT_ORDER.DESCEND ? "-" : ""}${values.sortColumn}`
      : undefined;

    const transformedValues = {
      ...preTransformDateFilters(values),
      defaultSort,
    };

    const result = worklist?.id
      ? await updateWorklist(transformedValues)
      : await createWorklist(transformedValues);
    if ("data" in result) {
      message.success("Worklist added successfully!");
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const defaultColumns = getDefaultColumns({});

  const preTransformedWorklist = useMemo(() => {
    const currentWorklist = { ...worklist };

    currentWorklist.groupIds = worklist?.groups?.map((group) => group.id);

    if (worklist?.defaultSort) {
      currentWorklist.sortColumn = worklist.defaultSort.replace(/^-/, "") ?? undefined;
      currentWorklist.sortOrder = worklist.defaultSort.startsWith("-")
        ? SORT_ORDER.DESCEND
        : SORT_ORDER.ASCEND;
    }

    currentWorklist.filters = {
      ...currentWorklist?.filters,
      excludeStatusCodes: currentWorklist?.filters?.excludeStatusCodes ?? undefined,
      includeStatusCodes: currentWorklist?.filters?.includeStatusCodes ?? undefined,
      creditorIds: currentWorklist?.filters?.creditorIds ?? undefined,
      excludeCreditorIds: currentWorklist?.filters?.excludeCreditorIds ?? undefined,
      creditorTagIds: currentWorklist?.filters?.creditorTagIds ?? undefined,
      excludeCreditorTagIds: currentWorklist?.filters?.excludeCreditorTagIds ?? undefined,
      debtorStates: currentWorklist?.filters?.debtorStates ?? undefined,
      excludeDebtorStates: currentWorklist?.filters?.excludeDebtorStates ?? undefined,
      debtorTagIds: currentWorklist?.filters?.debtorTagIds ?? undefined,
      excludeDebtorTagIds: currentWorklist?.filters?.excludeDebtorTagIds ?? undefined,
      assigneeIds: currentWorklist?.filters?.assigneeIds ?? undefined,
      excludeAssigneeIds: currentWorklist?.filters?.excludeAssigneeIds ?? undefined,
      includeProductOfferingIds: currentWorklist?.filters?.includeProductOfferingIds ?? undefined,
      excludeProductOfferingIds: currentWorklist?.filters?.excludeProductOfferingIds ?? undefined,
      salesRepIds: currentWorklist?.filters?.salesRepIds ?? undefined,
      excludeSalesRepIds: currentWorklist?.filters?.excludeSalesRepIds ?? undefined,
      creditorCodes: currentWorklist?.filters?.creditorCodes ?? undefined,
      excludeCreditorCodes: currentWorklist?.filters?.excludeCreditorCodes ?? undefined,
    };

    // transform date range values

    currentWorklist.filters = {
      ...currentWorklist?.filters,
      followUpDate: [
        currentWorklist?.filters?.followUpStartDate ?? null,
        currentWorklist?.filters?.followUpEndDate ?? null,
      ],
      excludeFollowUpDate: [
        currentWorklist?.filters?.excludeFollowUpDateStart ?? null,
        currentWorklist?.filters?.excludeFollowUpDateEnd ?? null,
      ],
      uploadDate: [
        currentWorklist?.filters?.uploadDateStart ?? null,
        currentWorklist?.filters?.uploadDateEnd ?? null,
      ],
      excludeUploadDate: [
        currentWorklist?.filters?.excludeUploadDateStart ?? null,
        currentWorklist?.filters?.excludeUploadDateEnd ?? null,
      ],
      includeDateOfService: [
        currentWorklist?.filters?.includeDateOfServiceStart ?? null,
        currentWorklist?.filters?.includeDateOfServiceEnd ?? null,
      ],
      excludeDateOfService: [
        currentWorklist?.filters?.excludeDateOfServiceStart ?? null,
        currentWorklist?.filters?.excludeDateOfServiceEnd ?? null,
      ],
      includeTurnoverDate: [
        currentWorklist?.filters?.includeTurnoverDateStart ?? null,
        currentWorklist?.filters?.includeTurnoverDateEnd ?? null,
      ],
      excludeTurnoverDate: [
        currentWorklist?.filters?.excludeTurnoverDateStart ?? null,
        currentWorklist?.filters?.excludeTurnoverDateEnd ?? null,
      ],
      includeDateOfFirstDelinquency: [
        currentWorklist?.filters?.includeDateOfFirstDelinquencyStart ?? null,
        currentWorklist?.filters?.includeDateOfFirstDelinquencyEnd ?? null,
      ],
      excludeDateOfFirstDelinquency: [
        currentWorklist?.filters?.excludeDateOfFirstDelinquencyStart ?? null,
        currentWorklist?.filters?.excludeDateOfFirstDelinquencyEnd ?? null,
      ],
      includeItemizationDate: [
        currentWorklist?.filters?.includeItemizationDateStart ?? null,
        currentWorklist?.filters?.includeItemizationDateEnd ?? null,
      ],
      excludeItemizationDate: [
        currentWorklist?.filters?.excludeItemizationDateStart ?? null,
        currentWorklist?.filters?.excludeItemizationDateEnd ?? null,
      ],
      includeChargeOffDate: [
        currentWorklist?.filters?.includeChargeOffDateStart ?? null,
        currentWorklist?.filters?.includeChargeOffDateEnd ?? null,
      ],
      excludeChargeOffDate: [
        currentWorklist?.filters?.excludeChargeOffDateStart ?? null,
        currentWorklist?.filters?.excludeChargeOffDateEnd ?? null,
      ],
      includeInterestStartDate: [
        currentWorklist?.filters?.includeInterestStartDateStart ?? null,
        currentWorklist?.filters?.includeInterestStartDateEnd ?? null,
      ],
      excludeInterestStartDate: [
        currentWorklist?.filters?.excludeInterestStartDateStart ?? null,
        currentWorklist?.filters?.excludeInterestStartDateEnd ?? null,
      ],
      includeInterestEndDate: [
        currentWorklist?.filters?.includeInterestEndDateStart ?? null,
        currentWorklist?.filters?.includeInterestEndDateEnd ?? null,
      ],
      excludeInterestEndDate: [
        currentWorklist?.filters?.excludeInterestEndDateStart ?? null,
        currentWorklist?.filters?.excludeInterestEndDateEnd ?? null,
      ],
    };

    return currentWorklist;
  }, [worklist]);

  return (
    <Modal
      confirmLoading={isCreateLoading || isUpdateLoading}
      maskClosable={false}
      title={title}
      onOk={onSubmit}
      onCancel={onCancel}
      open
      width={800}
    >
      <StyledForm layout="vertical" form={form} initialValues={preTransformedWorklist}>
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input placeholder="Worklist Name" />
        </Form.Item>
        <Form.Item name="groupIds" label="Roles">
          <Select
            popupMatchSelectWidth={false}
            allowClear
            mode="multiple"
            options={roles?.map((x) => ({ label: x.name, value: x.id }))}
            placeholder="Select roles"
            loading={isRolesLoading}
            disabled={isRolesLoading}
          />
        </Form.Item>
        <Form.Item name="userIds" label="Users">
          <Select
            popupMatchSelectWidth={false}
            allowClear
            mode="multiple"
            options={users?.map((x) => ({ label: x.name, value: x.id }))}
            placeholder="Select users"
            loading={isUsersLoading}
            disabled={isUsersLoading}
          />
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="sortColumn" label="Sort Column">
              <Select
                placeholder="Select one..."
                options={defaultColumns
                  ?.filter((source) => {
                    return !!source.sorter;
                  })
                  .map((source) => ({
                    label: source.title,
                    value: source.key,
                  }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="sortOrder" label="Sort Order">
              <Select
                placeholder="Select one..."
                options={Object.values(SORT_ORDER).map((order) => ({
                  label: capitalizeFirstLetter(order),
                  value: order,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <AccountFiltersStep />
      </StyledForm>
    </Modal>
  );
}

export default AddWorklistModal;

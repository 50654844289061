import { useFetchMeQuery } from "features/auth/authAPI";
import { MomentProvider } from "providers";
import AppearanceProvider from "providers/appearanceProvider";
import { Route, Routes } from "react-router-dom";

function getRoutes(routes) {
  return routes.map((route) => {
    return <Route key={route.path} {...route} />;
  });
}

function UserTypeRoute({ routes }) {
  const { data: me, isLoading } = useFetchMeQuery();

  if (isLoading) {
    return null;
  }

  const userType = me?.type;

  if (userType === "agency") {
    return (
      <MomentProvider>
        <AppearanceProvider>
          <Routes>{getRoutes(routes.agency)}</Routes>
        </AppearanceProvider>
      </MomentProvider>
    );
  }

  if (userType === "creditor") {
    return (
      <AppearanceProvider>
        <Routes>{getRoutes(routes.creditor)}</Routes>
      </AppearanceProvider>
    );
  }

  return null;
}

export default UserTypeRoute;

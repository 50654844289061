import { Form, Input, InputNumber, Modal, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { snakeToCamelCase } from "common/utils";
import { AktPercentageInput } from "components/aktPercentageInput";
import currency from "currency.js";
import { useAddFeePlanMutation, useUpdateFeePlanMutation } from "features/feePlans/feePlansAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function EditFeePlanModal({ title, open, onOk, onCancel, feePlan }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [addFeePlan] = useAddFeePlanMutation();
  const [editFeePlan] = useUpdateFeePlanMutation();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    const action = feePlan ? editFeePlan : addFeePlan;
    const result = await action(newRule);
    if ("data" in result) {
      const successMessage = feePlan
        ? "Fee plan updated successfully!"
        : "Fee plan added successfully!";
      message.success(successMessage);
      form.resetFields();
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const initialValues = {
    ...feePlan,
  };

  // value is between 0 and 1
  const validateNumber = (_, value) => {
    if (value === undefined) return Promise.resolve();
    // Regular expression to match positive integers or numbers with up to 2 decimal places
    const numberRegex = /^(?:\d+(?:\.\d{1,4})?)$/;

    if (!numberRegex.test(value)) {
      return Promise.reject(
        new Error("Please enter a positive number with up to 2 decimal places."),
      );
    }

    const parsedValue = currency(value, { precision: 4 }).value;
    if (parsedValue > 1) {
      return Promise.reject(new Error("Number must be less than or equal to 100."));
    }

    return Promise.resolve();
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm
        form={form}
        layout="vertical"
        validateMessages={{ required: "This is a required field" }}
        initialValues={initialValues}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name="name"
          label="Plan Name"
          rules={[{ required: true, message: "This field is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="flatFeePerAccountUpload"
          label="Flat Fee (Per Account Upload)"
          rules={[
            {
              pattern: /^[0-9.]+$/,
              message: "Please enter a valid number.",
            },
          ]}
          initialValue={0}
          tooltip="The flat amount take for each account uploaded."
        >
          <InputNumber prefix="$" step="0.01" />
        </Form.Item>
        <h5>Percent Contingency</h5>
        The percentage take for each payment category.
        <br />
        <br />
        {constants?.paymentCategories?.invoiceable.map((category) => (
          <Form.Item
            key={category.display}
            name={snakeToCamelCase(category.value)}
            label={category.display}
            rules={[
              {
                required: true,
                message: "This field is required.",
              },
              {
                validator: validateNumber,
              },
            ]}
          >
            <AktPercentageInput />
          </Form.Item>
        ))}
        {/* <h5>Account Matching Criteria</h5>
        Accounts that match the criteria below will follow this fee structure.
        <br />
        <br />
        <Form.Item
          name="minDebtBalance"
          label="Min Debt Balance"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid number.",
            },
          ]}
        >
          <InputNumber addonBefore="$" />
        </Form.Item>
        <Form.Item
          name="maxDebtBalance"
          label="Max Debt Balance"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid number.",
            },
          ]}
        >
          <InputNumber addonBefore="$" />
        </Form.Item>
        <Form.Item
          name="minAgeOfAccount"
          label="Min Age of Account"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid number.",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="maxAgeOfAccount"
          label="Max Age of Account"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Please enter a valid number.",
            },
          ]}
        >
          <InputNumber />
        </Form.Item> */}
      </StyledForm>
    </Modal>
  );
}

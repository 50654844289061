import { DownloadOutlined, FileTextOutlined } from "@ant-design/icons";
import { Alert, Button, Collapse, Modal, Row, Select, Steps, Table, Tooltip, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { camelToWords, downloadFileFromUrl, snakeToCamelCase } from "common/utils";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useGetCreditorPortalCreditorQuery } from "features/creditors/creditorPortal/creditorsAPI";
import CreditorAccountUploadParamsForm from "features/dataImport/components/creditorAccountUploadParamsForm";
import {
  useFetchCreditorPortalImportHistoryQuery,
  usePostCreditorPortalDataImportCreateMutation,
} from "features/dataImport/creditorPortal/dataImportAPI";
import { useFetchCreditorPortalProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchAllAccountUdfCustomFieldsDromoSchemaQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useFetchAllCreditorPortalDebtorUdfCustomFieldsDromoSchemaQuery } from "features/userDefinedFields/creditorPortal/debtorUserDefinedFieldsAPI";
import {
  DromoButton,
  getAccountSchemaFields,
  getMultipleCreditorAccountsSchemaFields,
} from "integrations/dromo";
import { convertToDromoCustomField } from "integrations/dromo/dromoUtils";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import styled from "styled-components";

const { Panel } = Collapse;

const StyledGlossaryHeader = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
`;

const StyledCollapse = styled(Collapse)`
  margin-top: 16px;
  background-color: #f5f5f5;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    align-items: center !important;
    padding-bottom: 0 !important;
    padding-left: 8px !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

const StyledSelect = styled(Select)`
  width: 250px;
  margin-left: 4px;
`;

const StyledGlossarySelect = styled(Select)`
  margin-left: 4px;
`;

const StyledFileTextOutlined = styled(FileTextOutlined)`
  margin-left: 4px;
  cursor: pointer;
  color: #1890ff;
`;

const StyledTable = styled(Table)`
  margin-top: 16px;
`;

const StyledSpan = styled.span`
  margin-left: 8px;
`;

export default function DataImport() {
  // const { data: constants } = useFetchBackendConstantsQuery();
  // const [postImportSkipTrace] = usePostImportSkipTraceMutation();
  const [uploadType, setUploadType] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [postDataImportCreate] = usePostCreditorPortalDataImportCreateMutation();
  const { data: importHistoryData } = useFetchCreditorPortalImportHistoryQuery();
  const { data: debtorCustomFieldsDromoSchema } =
    useFetchAllCreditorPortalDebtorUdfCustomFieldsDromoSchemaQuery();
  const { data: accountCustomFieldsDromoSchema } =
    useFetchAllAccountUdfCustomFieldsDromoSchemaQuery();
  const { data: productOfferings } = useFetchCreditorPortalProductOfferingsQuery();
  const { data: selectedCreditor } = useGetCreditorPortalCreditorQuery(
    { creditorId: additionalData?.creditorId },
    { skip: !additionalData?.creditorId, refetchOnMountOrArgChange: true },
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [errorFile, setErrorFile] = useState(null);
  const { data: me } = useFetchMeQuery();
  const [glossaryUploadType, setGlossaryUploadType] = useState("account_consumer");
  const [isColumnGlossaryModalOpen, setIsColumnGlossarysModalOpen] = useState(false);

  const isAccountUploadType = ["account_commercial", "account_consumer"].includes(uploadType);

  const submitCsv = async (data, { id, filename }) => {
    setErrorFile(null);
    const result = await postDataImportCreate({
      filename,
      uploadId: id,
      type: uploadType,
      ...additionalData,
    });

    const reset = () => {
      setCurrentStep(0);
      setUploadType(null);
      setAdditionalData(null);
    };

    if ("data" in result) {
      if (result.data.errorFile) {
        setErrorFile(result.data.errorFile);
        message.info("Data imported with errors");
      } else if (result.data.error) {
        message.error(result.data.error);
      } else {
        message.success("Data imported successfully");
        reset();
      }
    }

    if ("error" in result) {
      message.error("Failed to import data");
    }
  };

  const onTypeSelect = (value) => {
    setUploadType(value);
    setGlossaryUploadType(value);
    setCurrentStep(1);
  };

  const onAdditionalDataSubmit = (data) => {
    setAdditionalData(data);
    setCurrentStep(2);
  };

  const isValidAccountUpload = isAccountUploadType ? additionalData?.cbrClassCode : true;

  const isDromoButton = me && uploadType && isValidAccountUpload;

  const importTypes = useMemo(() => {
    return [
      {
        label: "Upload Account - Consumer",
        value: "account_consumer",
        schema: [
          ...getAccountSchemaFields("consumer"),
          ...(selectedCreditor?.accountCustomFieldsDromoSchema ?? []).map(
            convertToDromoCustomField,
          ),
          ...(debtorCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
        ],
      },
      {
        label: "Upload Account - Commercial",
        value: "account_commercial",
        schema: [
          ...getAccountSchemaFields("commercial"),
          ...(selectedCreditor?.accountCustomFieldsDromoSchema ?? []).map(
            convertToDromoCustomField,
          ),
          ...(debtorCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
        ],
      },
      {
        label: "Upload Multiple Clients Account - Consumer",
        value: "account_multiple_clients_consumer",
        schema: [
          ...getMultipleCreditorAccountsSchemaFields({
            accountType: "consumer",
            productOfferings,
          }),
          ...(accountCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
          ...(debtorCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
        ],
      },
      {
        label: "Upload Multiple Clients Account - Commercial",
        value: "account_multiple_clients_commercial",
        schema: [
          ...getMultipleCreditorAccountsSchemaFields({
            accountType: "commercial",
            productOfferings,
          }),
          ...(accountCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
          ...(debtorCustomFieldsDromoSchema ?? []).map(convertToDromoCustomField),
        ],
      },
    ];
  }, [
    selectedCreditor,
    accountCustomFieldsDromoSchema,
    debtorCustomFieldsDromoSchema,
    productOfferings,
  ]);

  const steps = [
    {
      title: "Choose Data Type",
      description: (
        <StyledSelect
          popupMatchSelectWidth={false}
          placeholder="Select one..."
          value={uploadType}
          onSelect={onTypeSelect}
          options={importTypes.map((importType) => ({
            label: importType.label,
            value: importType.value,
          }))}
          // options={constants?.thirdPartyImportTypes.map((type) => ({
          //   label: type.display,
          //   value: type.value,
          // }))}
        />
      ),
    },
    isAccountUploadType && {
      title: "Select Client",
      description: <CreditorAccountUploadParamsForm onSubmit={onAdditionalDataSubmit} />,
    },
    {
      title: (
        <>
          Upload File
          <Tooltip title="See Columns Glossary">
            <StyledFileTextOutlined onClick={() => setIsColumnGlossarysModalOpen(true)} />
          </Tooltip>
        </>
      ),
      description: isDromoButton && (
        <DromoButton
          fields={importTypes.find((type) => type.value === uploadType).schema}
          text="Click to Upload"
          onResults={submitCsv}
          importIdentifier={`accounts-agency:${me.agencyId}-type:${uploadType}`}
        />
      ),
    },
  ];

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => camelToWords(snakeToCamelCase(type)),
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format(DATE_TIME_FORMAT),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        return (
          <span>
            {camelToWords(snakeToCamelCase(status))}
            {record.errorFile && (
              <Button
                icon={<DownloadOutlined />}
                type="link"
                onClick={() => downloadFileFromUrl(record.errorFile)}
              >
                Download Error File
              </Button>
            )}
          </span>
        );
      },
    },
  ];

  const handleCancel = () => {
    setIsColumnGlossarysModalOpen(false);
  };

  return (
    <div>
      <Steps direction="vertical" current={currentStep} items={steps} />
      {errorFile && (
        <Alert
          type="warning"
          message={
            <span>
              Data may be partially uploaded. Click <a href={errorFile}>here</a> to download the
              list of errors for rows that were not uploaded
            </span>
          }
        />
      )}
      <StyledCollapse defaultActiveKey="history" bordered={false}>
        <StyledPanel header={<h3>Import History</h3>} key="history">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            bordered
            dataSource={importHistoryData}
          />
        </StyledPanel>
      </StyledCollapse>
      {isColumnGlossaryModalOpen && (
        <Modal
          title="Columns Glossary"
          open={isColumnGlossaryModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Row align="middle">
            <span>Upload Type: </span>
            <StyledGlossarySelect
              popupMatchSelectWidth={false}
              placeholder="Select Upload Type..."
              value={glossaryUploadType}
              // @ts-ignore
              onChange={(value) => setGlossaryUploadType(value)}
              options={importTypes.map((importType) => ({
                label: importType.label,
                value: importType.value,
              }))}
            />
          </Row>
          <StyledGlossaryHeader>Accepted Columns: </StyledGlossaryHeader>
          <StyledTable
            size="small"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 100],
            }}
            columns={[
              {
                title: (
                  <div>
                    <span>Available Columns</span>
                    <StyledSpan>(* indicates a required field)</StyledSpan>
                  </div>
                ),
                dataIndex: "label",
                key: "label",
                render: (label, record) => (
                  <span>
                    {/* @ts-ignore */}
                    {record.validators?.[0].validate === "required" && <span>*</span>}
                    {label}
                  </span>
                ),
              },
            ]}
            bordered
            dataSource={importTypes.find((type) => type.value === glossaryUploadType).schema ?? []}
          />
        </Modal>
      )}
    </div>
  );
}

import { Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import {
  useCreateTaskMutation,
  useFetchDebtorAccountsQuery,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { camelToWords, snakeToCamelCase } from "common/utils";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function SendLetterSequenceModal({ title, open, onOk, onCancel }) {
  const [form] = useForm();
  const { debtorId } = useParams();
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const { data: communicationMethods, isLoading: isCommunicationMethodsLoading } =
    useFetchCommunicationMethodsQuery();
  const { data: accounts, isLoading: isAccountsLoading } = useFetchDebtorAccountsQuery({
    debtorId,
  });

  const letterOptions = communicationMethods?.reduce((acc, method) => {
    if (!["third_party_letter", "in_house_letter"].includes(method.medium)) {
      return acc;
    }

    const mediumName = camelToWords(snakeToCamelCase(method.medium));
    const existingMedium = acc.find((option) => option.label === mediumName);
    const option = {
      label: method.letterCode ? `${method.letterCode} - ${method.name}` : method.name,
      value: method.id,
    };

    if (existingMedium) {
      existingMedium.options.push(option);
      return acc;
    }

    return [
      ...acc,
      {
        label: mediumName,
        options: [option],
      },
    ];
  }, []);

  const onSubmit = async () => {
    const values = await form.validateFields();

    // Form post-transformation
    values.templateVariables = (values.templateVariables ?? []).reduce((acc, templateField) => {
      acc[templateField.fieldName] = templateField.fieldValue;
      return acc;
    }, {});

    const result = await createTask({ ...values, debtorId });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(`Letter(s) scheduled to send on ${values.scheduledDate}`);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm layout="vertical" form={form}>
        <Form.Item
          label="Letter"
          name="communicationMethodId"
          rules={[{ required: true, message: "Select a letter" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            loading={isCommunicationMethodsLoading}
            options={letterOptions}
          />
        </Form.Item>
        <Form.Item
          label="Account(s)"
          name="accountIds"
          rules={[{ required: true, message: "Select an account" }]} // Only required for debt validation. This will need to be dynamic in the future when more letter types are supported
        >
          <Select
            mode="multiple"
            placeholder="Select one..."
            loading={isAccountsLoading}
            options={accounts?.map((account) => ({
              value: account.id,
              label: `ID: ${account.id}`,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="scheduledDate"
          label="Date to Send"
          rules={[{ required: true, message: "Select Date" }]}
          initialValue={moment().format(DATE_FORMAT)}
        >
          <AktDatePicker disabledDate={disabledDate} />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default SendLetterSequenceModal;

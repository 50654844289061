import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Upload, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useUserType } from "features/auth";
import { usePostDocumentsMutation } from "features/documentsTable/agencyPortal/documentsAPI";
import { usePostCreditorPortalDebtorDocumentsMutation } from "features/documentsTable/creditorPortal/documentsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function UploadDocumentModal({ debtorId, title, open, onOk, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const [uploadAgencyDocument] = usePostDocumentsMutation();
  const [uploadCreditorPortalDocument] = usePostCreditorPortalDebtorDocumentsMutation();
  const uploadDocument = isAgencyUserType ? uploadAgencyDocument : uploadCreditorPortalDocument;
  const [fileList, setFileList] = useState([]);
  const { data: constants } = useFetchBackendConstantsQuery();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const result = await uploadDocument({ ...values, debtorId });
      if ("data" in result) {
        form.resetFields();
        onOk(); // Close the modal using the parent callback
      }
      if ("error" in result) {
        form.setFields(formatError(result.error));
      }
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  // See https://ant.design/components/form/ `validateMessages` to see how to customize error
  // messages.
  const validateMessages = {
    required: "This is a required field",
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={handleOk} onCancel={onCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Name"
          name="filename"
          rules={[{ required: true }]}
          tooltip="This is a required field"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true }]}
          tooltip="This is a required field"
        >
          <Select
            options={constants?.documentTypes
              .filter((x) => x.value !== "account_bulk_upload" && x.value !== "dispute")
              .map((fileType) => ({
                label: fileType.display,
                value: fileType.value,
              }))}
          />
        </Form.Item>
        <Form.Item label="Notes" name="notes" initialValue="">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Upload"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true }]}
        >
          <Upload
            multiple={false}
            name="file"
            headers={{
              authorization: "authorization-text",
            }}
            onChange={(info) => {
              const { status } = info.file;
              if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            beforeUpload={(file) => {
              setFileList([...fileList, file]);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        {/* TODO: Consider if we want to allow bulk upload documents. For now only single.
        <Form.Item label="Dragger">
          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger name="files" action="/upload.do">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item> */}
      </StyledForm>
    </Modal>
  );
}

export default UploadDocumentModal;
